import { TChildren } from '@typescript/TGlobal';
import IsLoadingCom, {
  LoadingPageProps,
} from '@components/shared/IsLoadingCom';
import Default, { DefaultComProps } from '@pages/Default';

interface Props<T> {
  children: TChildren;
  isLoading: boolean;
  data: T[];
  isFetching?: boolean;
  defaultOptions?: DefaultComProps;
  isLoadingOptions?: LoadingPageProps;
}

function DataWrapper<T>({
  children,
  isLoading,
  data,
  defaultOptions,
  isFetching,
  isLoadingOptions,
}: Props<T>) {
  if (isLoading) {
    return <IsLoadingCom {...isLoadingOptions} />;
  }

  if (data.length === 0 && !isFetching) {
    return <Default {...defaultOptions} />;
  }

  return <div>{children}</div>;
}

export default DataWrapper;
