import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NavLink as ReactLink, useLocation } from 'react-router-dom';
import { toRem } from '@utils/functions';
import {
  DrawerContainer,
  DrawerHeader,
  SidebarSubTitle,
} from '@styles/sidebar/SSidebarStyles';
import {
  SidebarGeneralListItems,
  SidebarOtherListItems,
} from '@constants/CSidebar';
import customColors from '@theme/customColors';
import useUser from '@hooks/useUser';
import SidebarListItem from './SidebarListItem';

interface Props {
  isInsideHumbuggerMenu: boolean | undefined;
  openHelpCenter: () => void;
}

function SidebarDesktop({ isInsideHumbuggerMenu, openHelpCenter }: Props) {
  const location = useLocation();
  const [sidebarItems, setSidebarItems] = useState(SidebarGeneralListItems);

  const theme = useTheme();

  const [open, setOpen] = useState(true);

  const { currentUser } = useUser();

  const sidebarDrawerContainer = {
    height: 'calc(100% - 56px)',
    top: 56,
    background: 'transparent',
    borderRight: `1px solid ${customColors.borderDarkColor} !important`,
    backgroundColor: 'transparent',
    backdropFilter: 'blur(25px)',
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (currentUser) {
      setSidebarItems(SidebarGeneralListItems);
    } else {
      let newItemsOrder = SidebarGeneralListItems.slice();
      newItemsOrder = newItemsOrder.sort((a) => (a.isProjected ? 1 : -1));
      setSidebarItems(newItemsOrder);
    }
  }, [currentUser]);

  return (
    <Box className="SidebarDesktop" sx={{ display: 'flex' }}>
      <DrawerContainer
        PaperProps={{ sx: sidebarDrawerContainer }}
        variant="permanent"
        open={open}
        sx={{ paddingBlock: '1rem' }}
      >
        <DrawerHeader
          open={open}
          theme={theme}
          sx={{ paddingLeft: open ? toRem(20) : toRem(12) }}
        >
          <SidebarSubTitle open={open} variant="h6">
            General
          </SidebarSubTitle>
          {!isInsideHumbuggerMenu && (
            <IconButton
              sx={{
                display: 'flex',
                padding: '0.1rem',
                backgroundColor: customColors.sidebarCollapseButtonColor,
                color: customColors.primaryWithOpacity50,
              }}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}
        </DrawerHeader>
        <List
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: open ? `0 ${toRem(12)}` : '0.5rem',
            backgroundColor: 'transparent',
          }}
        >
          {sidebarItems.map((listItem, index) => {
            const isSplit =
              listItem?.isProjected && !sidebarItems[index - 1]?.isProjected;

            if (isSplit && !currentUser && open) {
              return (
                <Box key={listItem.text}>
                  <Divider
                    sx={{
                      fontSize: toRem(12),
                      color: theme.palette.text.secondary,
                      position: 'static',
                      backgroundColor: theme.palette.secondary.main,
                      height: 'unset',
                      width: 'unset',
                    }}
                  >
                    Login Required
                  </Divider>

                  <SidebarListItem
                    key={listItem.text}
                    {...listItem}
                    open={open}
                  />
                </Box>
              );
            }
            return (
              <SidebarListItem key={listItem.text} {...listItem} open={open} />
            );
          })}
        </List>
        <SidebarSubTitle
          open={open}
          variant="h6"
          sx={{ marginLeft: open ? toRem(20) : '1.6rem', marginTop: 'auto' }}
        >
          Other
        </SidebarSubTitle>
        <List
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: open ? `0 ${toRem(12)}` : '0.5rem',
            backgroundColor: 'transparent',
          }}
        >
          {SidebarOtherListItems.map((listItem) => {
            const Svg = listItem.icon;
            if (listItem.isProjected && !currentUser) {
              return null;
            }
            if (listItem.text === 'Help & Support') {
              return (
                <ListItemButton
                  key={listItem.text}
                  sx={{
                    padding: 0,
                    paddingLeft: '0.5rem',
                    backgroundColor:
                      listItem.href === location.pathname
                        ? customColors.boxColor15
                        : 'transparent',
                    minHeight: 42,
                    justifyContent: open ? 'initial' : 'center',
                    borderRadius: '6px',
                  }}
                  onClick={openHelpCenter}
                  disableRipple
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 1,
                      justifyContent: 'center',
                    }}
                  >
                    <Svg
                      color={
                        listItem.href === location.pathname
                          ? theme.palette.primary.main
                          : theme.palette.primary.dark
                      }
                      width={18}
                      height={18}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={listItem.text}
                    primaryTypographyProps={{ variant: 'button' }}
                    sx={{ display: open ? 'inline-block' : 'none' }}
                  />
                </ListItemButton>
              );
            }
            return (
              <ListItem
                key={listItem.text}
                disablePadding
                sx={{ display: 'block', paddingBottom: '0.3rem' }}
              >
                {!listItem.internLink ? (
                  <ListItemButton
                    component="a"
                    href={listItem.href}
                    disabled={listItem.disabled}
                    target="_blank"
                    sx={{
                      padding: 0,
                      paddingLeft: '0.5rem',
                      backgroundColor:
                        listItem.href === location.pathname
                          ? 'primary.200'
                          : 'transparent',
                      minHeight: 42,
                      justifyContent: open ? 'initial' : 'center',
                      borderRadius: '6px',
                    }}
                    disableRipple
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'center',
                      }}
                    >
                      <Svg
                        fill={theme.palette.primary.main}
                        width={18}
                        height={18}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={listItem.text}
                      primaryTypographyProps={{ variant: 'button' }}
                      sx={{ display: open ? 'inline-block' : 'none' }}
                    />
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    component={ReactLink}
                    to={listItem.href}
                    disabled={listItem.disabled}
                    sx={{
                      padding: 0,
                      paddingLeft: '0.5rem',
                      backgroundColor:
                        listItem.href === location.pathname
                          ? customColors.boxColor15
                          : 'transparent',
                      minHeight: 42,
                      justifyContent: open ? 'initial' : 'center',
                      borderRadius: '6px',
                    }}
                    disableRipple
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'center',
                      }}
                    >
                      <Svg
                        color={
                          listItem.href === location.pathname
                            ? theme.palette.primary.main
                            : theme.palette.primary.dark
                        }
                        width={18}
                        height={18}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={listItem.text}
                      primaryTypographyProps={{ variant: 'button' }}
                      sx={{ display: open ? 'inline-block' : 'none' }}
                    />
                  </ListItemButton>
                )}
              </ListItem>
            );
          })}
        </List>
      </DrawerContainer>
    </Box>
  );
}

export default SidebarDesktop;
