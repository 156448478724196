import { TChildren } from '@typescript/TGlobal';
import Lottie from 'lottie-react';
import LogoLoadingAnimation from '@animations/lv-loading-animation.json';

interface Props {
  children: TChildren;
  isFetching: boolean;
}

function CardWrapper({ children, isFetching }: Props) {
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: isFetching ? 'block' : 'none',
          width: 100,
          height: 100,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50% , -50%)',
        }}
      >
        <Lottie
          animationData={LogoLoadingAnimation}
          loop
          width={40}
          height={40}
        />
      </div>
      <div
        className="button-container"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          background: 'transparent',
          justifyContent: 'center',
          border: 'none',
          padding: 0,
          margin: 0,
          textAlign: 'center',
          color: 'inherit',
          opacity: isFetching ? 0.5 : 1,
          cursor: isFetching ? 'not-allowed' : 'default',
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default CardWrapper;
