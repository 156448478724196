import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import HelpCenter from '@pages/HelpCenter';
import SidebarResponsive from '@components/sidebar/SidebarResponsive';
import SidebarDesktop from '@components/sidebar/SidebarDesktop';

function Sidebar({
  isInsideHumbuggerMenu,
}: {
  isInsideHumbuggerMenu?: boolean;
}) {
  const theme = useTheme();

  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  const [openHelpCenter, setOpenHelpCenter] = useState<boolean>(false);

  return (
    <Box className="Sidebar">
      {!downLg || isInsideHumbuggerMenu ? (
        <SidebarDesktop
          isInsideHumbuggerMenu={isInsideHumbuggerMenu}
          openHelpCenter={() => setOpenHelpCenter(true)}
        />
      ) : (
        <SidebarResponsive />
      )}

      <HelpCenter
        open={openHelpCenter}
        onClose={() => setOpenHelpCenter(false)}
      />
    </Box>
  );
}

export default Sidebar;
