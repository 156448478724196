import { Box, Typography } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';

import { useFormik } from 'formik';
import { TProjectCreateDetailsInitialValues } from '@typescript/TProjectDetails';
import { OverviewTitle } from '@styles/project/SProjectSinglePage';

interface Props {
  formik: ReturnType<typeof useFormik<TProjectCreateDetailsInitialValues>>;
}

function ProjectCreateOverview({ formik }: Props) {
  const { errors, values, handleBlur, handleChange, touched } = formik;

  return (
    <Box>
      <OverviewTitle mb={2} mt={4}>
        Overview
      </OverviewTitle>
      {errors.overviewText && touched.overviewText && (
        <Typography variant="h6" color="error" mb={1}>
          {errors.overviewText}
        </Typography>
      )}
      <MDEditor
        height={500}
        value={values.overviewText}
        textareaProps={{
          id: 'overviewText',
          onChange: handleChange,
          onBlur: handleBlur,
        }}
      />
    </Box>
  );
}
export default ProjectCreateOverview;
