import { Button, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import uuid from 'react-uuid';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useFormik } from 'formik';
import {
  TProjectCreateDetailsInitialValues,
  TSocialCardObj,
} from '@typescript/TProjectDetails';
import { projectSocialInitialValues } from '@formik/project/ProjectInitialData';
import { ProjectSocialValidationSchema } from '@formik/project/ProjectValidation';
import {
  RoadmapAddBtnStyled,
  SocialCard,
} from '@styles/project/SProjectCreate';
import { CloseBtn, FieldStyled } from '@styles/modal/SModal';
import { flexCentered } from '@styles/shared/SCss';
import { useMoralisFile } from 'react-moralis';
import { OverviewTitle } from '@styles/project/SProjectSinglePage';
import useNotification from '@hooks/useNotification';

interface Props {
  formik: ReturnType<typeof useFormik<TProjectCreateDetailsInitialValues>>;
}

function ProjectCreateSocials({ formik: ctxFormik }: Props) {
  const {
    values: ctxValues,
    setValues: setCtxValues,
    setTouched: setCtxTouched,
    touched: ctxTouched,
    errors: ctxErrors,
  } = ctxFormik;

  const { notifySuccess } = useNotification();

  const { saveFile } = useMoralisFile();

  const [addItem, setAddItem] = useState(false);

  const addSocialCard = (socialCard: TSocialCardObj) => {
    setCtxTouched({ socialCardsTouched: true });
    setCtxValues((prev) => ({
      ...prev,
      socialCards: [...prev.socialCards, socialCard],
    }));
  };

  const removeSocialCard = (id: string) => {
    setCtxTouched({ socialCardsTouched: true });
    setCtxValues((prev) => ({
      ...prev,
      socialCards: prev.socialCards.filter(
        (socialCard) => socialCard.id !== id,
      ),
    }));
  };

  const formik = useFormik({
    initialValues: projectSocialInitialValues,
    validationSchema: ProjectSocialValidationSchema,
    onSubmit: (values) => {
      addSocialCard(values);
      formik.resetForm();
      formik.setValues({ ...projectSocialInitialValues, id: uuid() });
      setAddItem(false);
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setValues,
  } = formik;

  const handleAddSocialImage = async (target: HTMLInputElement) => {
    const file = target?.files?.[0];
    if (file) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });
      const url = fileIpfs?._url;

      if (!url) {
        throw Error(`No url found for ${target?.files?.[0]}`);
      }

      setValues((prev) => ({ ...prev, imageUrl: url }));
      notifySuccess('Social image added!');
    }
  };

  useEffect(() => {
    setValues((prev) => ({ ...prev, id: uuid() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <OverviewTitle mb={2} mt={4}>
        Socials
      </OverviewTitle>
      {ctxErrors.socialCards && ctxTouched.socialCardsTouched && (
        <Typography variant="h6" color="error" mb={1}>
          {ctxErrors.socialCards as string}
        </Typography>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: '0.5rem',
          marginBottom: '0.5rem',
        }}
      >
        {ctxValues.socialCards.map((social) => (
          <SocialCard key={social.id}>
            <CloseBtn
              sx={{ position: 'unset' }}
              onClick={() => removeSocialCard(social.id)}
            >
              <CloseIcon sx={{ width: 20, height: 20, opacity: 0.5 }} />
            </CloseBtn>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '3rem',
                paddingTop: '1.5rem',
              }}
            >
              <a
                aria-label="Social link"
                href={social.url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={social.imageUrl}
                  width={45}
                  height={45}
                  style={{ borderRadius: '6px' }}
                  alt=""
                />
              </a>
            </Box>
          </SocialCard>
        ))}
        {addItem ? (
          <form onSubmit={handleSubmit}>
            <SocialCard sx={{ paddingTop: '0', position: 'relative' }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                <CloseBtn
                  sx={{ position: 'unset' }}
                  onClick={() => setAddItem(false)}
                >
                  <CloseIcon
                    sx={{
                      width: 20,
                      height: 20,
                      opacity: 0.5,
                    }}
                  />
                </CloseBtn>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingInline: '1rem',
                    marginTop: '10px',
                  }}
                >
                  <FieldStyled
                    id="url"
                    name="url"
                    label="Url..."
                    onBlur={handleBlur}
                    onInput={handleChange}
                    value={values.url}
                    helperText={touched.url ? errors.url : ''}
                    error={Boolean(errors.url) && touched.url}
                  />
                  {values.imageUrl ? (
                    <img
                      src={values.imageUrl}
                      width={50}
                      height={50}
                      style={{
                        borderRadius: '6px',
                        marginBlock: '0.5rem',
                      }}
                      alt=""
                    />
                  ) : (
                    <Button
                      component="label"
                      htmlFor="socialImage"
                      sx={{
                        padding: '0',
                        marginInline: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <Box sx={{ ...flexCentered, flexDirection: 'column' }}>
                        <AddPhotoAlternateIcon
                          sx={{ width: 36, height: 36, margin: '0.5rem' }}
                        />
                        {touched.imageUrl && errors.imageUrl && (
                          <Typography
                            variant="h6"
                            color="error.main"
                            sx={{
                              fontSize: '12px !important',
                              fontWeight: '400',
                            }}
                          >
                            Required!
                          </Typography>
                        )}
                      </Box>
                      <input
                        accept="image/*"
                        hidden
                        id="socialImage"
                        multiple
                        type="file"
                        onChange={(event) => handleAddSocialImage(event.target)}
                      />
                    </Button>
                  )}
                </Box>
                <Button
                  variant="outlined"
                  sx={{
                    marginBlock: '0.5rem',
                    borderRadius: '4px',
                    marginInline: '1rem',
                  }}
                  type="submit"
                >
                  Create
                </Button>
              </Box>
            </SocialCard>
          </form>
        ) : (
          <RoadmapAddBtnStyled
            sx={{ height: '160px' }}
            onClick={() => setAddItem(true)}
          >
            <AddOutlinedIcon sx={{ width: 30, height: 30, opacity: 0.5 }} />
          </RoadmapAddBtnStyled>
        )}
      </Box>
    </>
  );
}

export default ProjectCreateSocials;
