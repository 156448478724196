import { Box, Button, Typography } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import CloseIcon from '@mui/icons-material/Close';
import useProjectDetails from '@hooks/useProjectDetails';

import { useFormik } from 'formik';
import { useState } from 'react';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { TProjectDetails } from '@typescript/TProjectDetails';
import { EditBtnStyle } from '@styles/project/SProjectEdit';
import { ProjectEditOverviewValidationSchema } from '@formik/project/ProjectValidation';
import { projectEditOverviewInitialValues } from '@formik/project/ProjectInitialData';
import useNotification from '@hooks/useNotification';

interface Props {
  projectDetails: TProjectDetails;
  onClose: () => void;
}
function ProjectEditOverview({ projectDetails, onClose }: Props) {
  const { updateProjectDetails } = useProjectDetails();

  const { notifyError, notifySuccess } = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: projectEditOverviewInitialValues(
      projectDetails.overviewText || '',
    ),
    validationSchema: ProjectEditOverviewValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await updateProjectDetails({
          overviewText: values.overviewText,
          id: projectDetails.id,
        });
        setIsLoading(false);
        notifySuccess('Overview updated!');
      } catch (error) {
        notifyError('Overview update failed!');
        throw error;
      } finally {
        onClose();
      }
    },
  });

  const { values, handleBlur, handleChange, errors, touched } = formik;

  const handleUpdateOverview = () => {
    formik.submitForm();
  };

  if (isLoading) return <IsLoadingCom dimension="medium" />;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          mb: 1,
          gap: 1,
        }}
      >
        <Button onClick={handleUpdateOverview} variant="outlined">
          Save
        </Button>
        <Button onClick={onClose} sx={EditBtnStyle}>
          <CloseIcon sx={{ width: 20, height: 20 }} />
        </Button>
      </Box>
      {errors.overviewText && touched.overviewText && (
        <Typography variant="h6" color="error" mb={1}>
          {errors.overviewText}
        </Typography>
      )}
      <MDEditor
        height={500}
        value={values.overviewText}
        textareaProps={{
          id: 'overviewText',
          onChange: handleChange,
          onBlur: handleBlur,
        }}
      />
    </>
  );
}
export default ProjectEditOverview;
