/* eslint-disable max-len */
import { ListingTradeStatus, OfferTradeStatus } from '@constants/CDataStatus';
import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

interface Props {
  listingStatus: ListingTradeStatus | null;
  offerStatus: OfferTradeStatus | null;
  sx?: TypographyProps | null;
}

function TableStatuses({ listingStatus, offerStatus, sx }: Props) {
  const theme = useTheme();

  const [currentColor, setCurrentColor] = useState<string>('');

  const [currentTooltipMessage, setCurrentTooltipMessage] =
    useState<string>('');

  const statusColors = {
    warning: theme.palette.warning.light,
    danger: theme.palette.error.main,
    success: theme.palette.success.main,
    info: theme.palette.warning.main,
  } as const;

  const checkListingStatus = () => {
    switch (listingStatus) {
      case ListingTradeStatus.AVAILABLE:
        setCurrentColor(statusColors.success);
        setCurrentTooltipMessage(
          'This Order is available for trading. You can interact with it.',
        );
        break;
      case ListingTradeStatus.LISTED:
        setCurrentColor(statusColors.warning);
        setCurrentTooltipMessage('Your Order is available to all traders.');
        break;
      case ListingTradeStatus.DELETED:
        setCurrentColor(statusColors.danger);
        setCurrentTooltipMessage('This Order was deleted.');
        break;
      case ListingTradeStatus.EXPIRED:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage('This Order expired.');
        break;
      case ListingTradeStatus.INSUFFICIENT_FUNDS:
        setCurrentColor(statusColors.danger);
        setCurrentTooltipMessage(
          "You don't have enough funds in your connected wallet.",
        );
        break;
      case ListingTradeStatus.INSUFFICIENT_TOKENS:
        setCurrentColor(statusColors.danger);
        setCurrentTooltipMessage("You don't have enough tokens.");
        break;
      case ListingTradeStatus.TOKENS_SENT:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage(
          'Your tokens are locked and now you have to wait for the Buyer to complete the trade.',
        );
        break;
      case ListingTradeStatus.SUSPENDED:
        setCurrentColor(statusColors.warning);
        setCurrentTooltipMessage(
          // eslint-disable-next-line max-len
          'This Sell Order has an accepted offer and is suspended temporarily. If the offer is rejected before completing the trade, the order will be available again.',
        );
        break;
      case ListingTradeStatus.TOKENS_RECEIVED:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage(
          'You received the confirmation for your Buy Order. Now you can complete the trade.',
        );
        break;
      case ListingTradeStatus.TRANSFER_IN_PROGRESS:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage(
          'This Order has a transaction created and is waiting for confirmation. You can not interact with it.',
        );
        break;
      case ListingTradeStatus.WAITING_FOR_BUYER:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage(
          'This Buy Order received the tokens and now the Buyer has to complete the trade.',
        );
        break;

      default:
        throw Error('No Listing Status found in the platform!');
    }
  };

  const checkOffersStatus = () => {
    switch (offerStatus) {
      case OfferTradeStatus.OFFER_ACCEPTED:
        setCurrentColor(statusColors.success);
        setCurrentTooltipMessage(
          'Your offer has been accepted. You can now complete the trade.',
        );
        break;
      case OfferTradeStatus.OFFER_EXPIRED:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage('This offer expired.');
        break;
      case OfferTradeStatus.INSUFFICIENT_FUNDS:
        setCurrentColor(statusColors.danger);
        setCurrentTooltipMessage(
          'You can not complete the offer trade due to insufficient funds on your connected wallet.',
        );
        break;
      case OfferTradeStatus.OFFER_RECEIVED:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage(
          'You received an offer for your WTS negotiable listing. You can now accept or reject the offer.',
        );
        break;
      case OfferTradeStatus.OFFER_REJECTED:
        setCurrentColor(statusColors.danger);
        setCurrentTooltipMessage('This offer was rejected.');
        break;
      case OfferTradeStatus.OFFER_SENT:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage(
          'Your offer was sent and now you wait for the seller to accept or reject your offer.',
        );
        break;
      case OfferTradeStatus.OFFER_SUSPENDED:
        setCurrentColor(statusColors.warning);
        setCurrentTooltipMessage(
          'This offer was suspended because the seller accepted another offer for this order. If the seller is changing his mind and rejects that offer, he can again accept your offer, until then, this offer is suspended.',
        );
        break;
      case OfferTradeStatus.WAITING_FOR_OFFERER:
        setCurrentColor(statusColors.warning);
        setCurrentTooltipMessage(
          'You accepted and now you have to wait for the Offerer to complete the trade.',
        );
        break;
      case OfferTradeStatus.TRANSFER_IN_PROGRESS:
        setCurrentColor(statusColors.info);
        setCurrentTooltipMessage(
          'This order has a transaction created and is waiting for confirmation. You can not interact with it.',
        );
        break;

      default:
        throw Error('No Offer Status found in the platform!');
    }
  };

  useEffect(() => {
    if (listingStatus) {
      checkListingStatus();
    }
    if (offerStatus) {
      checkOffersStatus();
    }

    if (!offerStatus && !listingStatus) {
      throw Error('No listing status or offer status provided!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingStatus, offerStatus]);

  return (
    <Tooltip placement="top-start" title={currentTooltipMessage}>
      <Typography
        sx={{
          color: currentColor,
          fontSize: '14px',
          whiteSpace: 'initial',
          ...sx,
        }}
      >
        {listingStatus || offerStatus}
      </Typography>
    </Tooltip>
  );
}

export default TableStatuses;
