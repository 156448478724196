import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { NavLink as ReactLink, useLocation } from 'react-router-dom';
import customColors from '@theme/customColors';
import { toRem } from '@utils/functions';
import useActionOrConnect from '@hooks/useActionOrConnect';

import useUser from '@hooks/useUser';

interface ListItemProp {
  text: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  href: string;
  isProjected?: boolean;
  open: boolean;
}

function ListItemComponent({
  text,
  icon,
  href,
  isProjected,
  open,
}: ListItemProp) {
  const theme = useTheme();
  const { currentUser } = useUser();
  const location = useLocation();
  const isProtected = isProjected && !currentUser;
  const Svg = icon;
  const handleActionOrConnect = useActionOrConnect();

  return (
    <ListItem
      disablePadding
      sx={{
        paddingBottom: '0.3rem',
        color: isProtected
          ? theme.palette.text.secondary
          : theme.palette.primary.main,
      }}
    >
      <ListItemButton
        onClickCapture={() => {
          if (isProtected) {
            handleActionOrConnect();
          }
        }}
        component={ReactLink}
        to={isProtected ? '/projects' : href}
        sx={{
          padding: 0,
          paddingLeft: '0.5rem',
          minHeight: 42,
          backgroundColor:
            href === location.pathname
              ? customColors.boxColor15
              : 'transparent',
          justifyContent: open ? 'initial' : 'center',
          borderRadius: '6px',
        }}
        disableRipple
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Svg
            color={
              href === location.pathname
                ? theme.palette.primary.main
                : theme.palette.primary.dark
            }
            width={18}
            height={18}
          />
        </ListItemIcon>
        <Box>
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              sx: { fontWeight: 700, fontSize: toRem(14) },
            }}
            sx={{
              display: open ? 'inline-block' : 'none',
            }}
          />
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

export default ListItemComponent;
