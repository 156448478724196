import { Box, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BoxContainer, ProjectName } from '@styles/project/SProjectCardElem';
import {
  borderRadius6,
  greenTextShadow,
  redTextShadow,
} from '@styles/shared/SCss';
import { formatNr } from '@utils/functions';
import { CardHeader } from '@styles/portfolio/SPortfolio';
import useStyles from '@hooks/useStyles';
import { TEthAllocation } from '@typescript/models/EthAllocation.model';

interface Props {
  ethAllocation: TEthAllocation;
}

function EthAllocationCard({ ethAllocation }: Props) {
  const { border1pxSecondaryLight } = useStyles();

  const navigate = useNavigate();

  const { project } = ethAllocation.ethProject;

  const handleCardHeaderLink = () => {
    navigate(`/projects/${project.slug}`);
  };

  return (
    <BoxContainer key={ethAllocation.id}>
      <CardHeader onClick={handleCardHeaderLink}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={project.image}
            width={40}
            height={40}
            alt=""
            style={borderRadius6}
          />
          <ProjectName>{project.name}</ProjectName>
          <Typography variant="h3" sx={{ opacity: 0.5 }}>
            {project.symbol}
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={{
            color: project.performance >= 0 ? 'success.main' : 'error.main',
            fontWeight: '400',
            textShadow:
              project.performance >= 0 ? greenTextShadow : redTextShadow,
            ml: '0.5rem',
          }}
        >
          {project.performance >= 0 && '+'}
          {formatNr(project.performance, undefined, 2)}%
        </Typography>
      </CardHeader>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: border1pxSecondaryLight,
        }}
      >
        <Box
          sx={{
            padding: '1rem',
            flex: 1,
          }}
        >
          <Typography variant="h6" mb={2} sx={{ opacity: 0.5 }}>
            Legion Invested
          </Typography>
          <Tooltip title={ethAllocation.investment} placement="top-start">
            <Typography variant="h4" sx={{ fontWeight: '400' }}>
              {formatNr(ethAllocation.investment, undefined, 0)}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </BoxContainer>
  );
}

export default EthAllocationCard;
