import DataGridWrapper from '@components/shared/DataGridWrapper';
import DataWrapper from '@components/shared/DataWrapper';
import { getOffersColumnsWithActions } from '@components/tables-columns/OffersColumns';
import { OfferAction } from '@constants/CDataActions';
import { NO_OFFERS_MS } from '@constants/CDefaultMessages';
import useUser from '@hooks/useUser';
import { useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useGetMyActiveOffersQuery } from '@store/api/offerApi';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import { TOffer } from '@typescript/models/Offer.model';
import { Svgs } from '@utils/svgs';
import { useState } from 'react';

interface Props {
  changeAction: (newOffer: TOffer, newAction: OfferAction) => void;
}

function ActiveOffers({ changeAction }: Props) {
  const { insufficientFunds, currentUser } = useUser();

  const theme = useTheme();

  const [pageSize, setPageSize] = useState<number>(5);

  const [page, setPage] = useState<number>(0);

  const {
    data: { resource: offers, count } = {
      resource: [],
      count: 0,
    },
    isLoading,
    isFetching,
  } = useGetMyActiveOffersQuery(
    paginationZod.parse({ page: page + 1, pageSize }),
  );

  const DataGridRemovedScroll = {
    ...DataGridCustomStyles,
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: 'calc(100vh - 340px)',
      overflow: 'auto!important',
      paddingBottom: '2px',
      [theme.breakpoints.up('2xl')]: {
        maxHeight: 'calc(100vh - 340px)',
        overflowX: 'hidden !important',
        overflowY: 'auto !important',
      },
    },
  };

  return (
    <DataWrapper
      isLoading={isLoading}
      data={offers}
      defaultOptions={{
        icon: Svgs.IconTransactions,
        text: NO_OFFERS_MS,
        fullHeight: true,
      }}
      isLoadingOptions={{ sx: { height: '100%' } }}
    >
      <DataGridWrapper isFetching={isFetching}>
        <DataGrid
          sx={DataGridRemovedScroll}
          rows={offers}
          columns={getOffersColumnsWithActions(
            changeAction,
            insufficientFunds,
            currentUser,
          )}
          autoHeight
          disableSelectionOnClick
          initialState={{
            pagination: { pageSize, page },
          }}
          paginationMode="server"
          rowCount={count}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
        />
      </DataGridWrapper>
    </DataWrapper>
  );
}

export default ActiveOffers;
