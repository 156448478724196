import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { EditBtnStyle } from '@styles/project/SProjectEdit';

interface Props {
  openEditSection: () => void;
}
function EditIconCom({ openEditSection }: Props) {
  return (
    <Button onClick={openEditSection} sx={{ ...EditBtnStyle }}>
      <EditIcon sx={{ width: 20, height: 20 }} />
    </Button>
  );
}

export default EditIconCom;
