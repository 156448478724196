import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link as ScrollLink } from 'react-scroll';
import { TProjectDetails } from '@typescript/TProjectDetails';
import { RightSidebarLinks } from '@constants/CSidebar';
import useStyles from '@hooks/useStyles';

interface Props {
  projectDetails: TProjectDetails;
}
function ProjectSidebarResponsive({ projectDetails }: Props) {
  const { palette } = useTheme();

  const { border1pxSecondaryLight } = useStyles();

  const setSidebarDetailsLinks = () =>
    Object.keys(projectDetails).map((objectKey) =>
      RightSidebarLinks.map((sidebarEl) => {
        if (objectKey === sidebarEl.key) {
          return (
            <ScrollLink
              to={sidebarEl.hash.slice(1)}
              key={sidebarEl.key}
              style={{
                color: 'primary.main',
                opacity: 0.5,
                fontWeight: 500,
                fontSize: '0.8rem',
                cursor: 'pointer',
              }}
              activeClass="is-active"
              spy
              smooth
              offset={-100}
            >
              {sidebarEl.text}
            </ScrollLink>
          );
        }
        return null;
      }),
    );

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '57px',
        left: 0,
        zIndex: 2,
        width: '100%',
      }}
    >
      <Accordion
        sx={{ backgroundColor: palette.background.default, mb: 0 }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowUpIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ py: '0.5rem', borderBottom: border1pxSecondaryLight }}
        >
          <Typography sx={{ fontSize: '15px', fontWeight: 500 }}>
            Overview
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'grid', gap: '1.5rem', mt: 2 }}>
            {setSidebarDetailsLinks()}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default ProjectSidebarResponsive;
