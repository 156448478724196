import { ProjectStatus } from '@constants/CProject';
import useProject from '@hooks/useProject';
import { LinearProgress, Typography } from '@mui/material';
import customColors from '@theme/customColors';
import { TProject } from '@typescript/models/Project.model';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  project: TProject;
}

function ProjectStatusCom({ project }: Props) {
  const { getProjectProgress, getProjectProgressInvestment } = useProject();

  const [text, setText] = useState<string>('');

  const [textColor, setTextColor] = useState<string>('');

  const [textShadowColor, setTextShadowColor] = useState<string>('');

  const [progressBarBoxColor, setProgressBarBoxColor] = useState<string>('');

  const [progressBarShadowColor, setProgressBarShadowColor] =
    useState<string>('');

  const progress =
    project.status === ProjectStatus.LIVE ||
    project.status === ProjectStatus.LIVE_PRIVATE
      ? getProjectProgressInvestment(project)
      : getProjectProgress(project);

  const textStyle = {
    height: '25px',
    borderRadius: '4px',
    fontWeight: 500,
    marginLeft: 'auto',
    fontSize: '13px',
    padding: '3px 0px',
    display: 'block',
    flexShrink: 0,
    textShadow: `0 0 8px ${textShadowColor}`,
    background: textColor,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  const linearProgressStyle = {
    height: '10px',
    borderRadius: '6px',
    background: progressBarBoxColor,
    '& .MuiLinearProgress-bar': {
      borderRadius: '6px',
      background: textColor,
      boxShadow: `0 0 8px ${progressBarShadowColor}`,
    },
  };

  const handleStatusColors = useCallback(() => {
    switch (project.status) {
      case ProjectStatus.NOT_LAUNCHED:
        setTextColor(customColors.blueGradient);
        setTextShadowColor(customColors.shadowBlue);
        setText('Not launched');
        // TODO: check no progress bar anymore in design?
        setProgressBarBoxColor(customColors.opacityBlueGradient);
        setProgressBarShadowColor(customColors.shadowBlue);
        break;
      case ProjectStatus.DISTRIBUTING:
        setTextColor(customColors.orangeGradient);
        setTextShadowColor(customColors.shadowOrange);
        setText('Distributing');
        setProgressBarBoxColor(customColors.opacityOrangeGradient);
        setProgressBarShadowColor(customColors.shadowOrange);
        break;
      case ProjectStatus.COMPLETED:
        setTextColor(customColors.limegreenGradient);
        setTextShadowColor(customColors.shadowBlue);
        setText('Completed');
        setProgressBarBoxColor(customColors.opacityLimegreenGradient);
        setProgressBarShadowColor(customColors.shadowGreen);
        break;

      case ProjectStatus.LIVE:
        setTextColor(customColors.warmGreyGradient);
        setTextShadowColor(customColors.warmGrey30);
        setText('Live');
        break;

      case ProjectStatus.LIVE_PRIVATE:
        setTextColor(customColors.warmGreyGradient);
        setTextShadowColor(customColors.warmGrey30);
        setText('Private');
        break;

      case ProjectStatus.REFUNDED:
        setTextColor(customColors.orangeGradient);
        setTextShadowColor(customColors.shadowOrange);
        setText('Refunded');
        break;

      default:
        throw Error(`Invalid project status ${project.status}}`);
    }
  }, [project.status]);

  useEffect(() => {
    handleStatusColors();
  }, [handleStatusColors]);

  return {
    label: <Typography sx={textStyle}>{text}</Typography>,
    progressBar: (
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={linearProgressStyle}
      />
    ),
    progress,
  };
}

export default ProjectStatusCom;
