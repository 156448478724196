/* eslint-disable max-len */
import { WrapperContainer } from '@styles/project/SProjectSinglePage';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import ProjectOverview from '@components/project/project-page/project-details-comp/ProjectOverview';
import ProjectTeam from '@components/project/project-page/project-details-comp/ProjectTeam';
import ProjectPartners from '@components/project/project-page/project-details-comp/ProjectPartners';
import ProjectSocial from '@components/project/project-page/project-details-comp/ProjectSocial';
import {
  TEditProjectDetails,
  TProject,
} from '@typescript/models/Project.model';
import ProjectSidebarResponsive from './ProjectSidebarResponsive';
import ProjectSidebar from './ProjectSidebar';
import ProjectEditOverview from '../project-edit/ProjectEditOverview';
import ProjectTeamEdit from '../project-edit/ProjectEditTeam';
import ProjectEditPartners from '../project-edit/ProjectEditPartners';
import ProjectEditSocials from '../project-edit/ProjectEditSocials';

interface Props {
  project: TProject;
}

function ProjectDetails({ project }: Props) {
  const projectDetails = { ...project.details };

  const { breakpoints } = useTheme();

  const downMd = useMediaQuery(breakpoints.down('md'));

  const [editSection, setEditSection] = useState<TEditProjectDetails>({
    overview: false,
    teams: false,
    partners: false,
    social: false,
  });

  const changeEditSection = (
    value: boolean,
    section: keyof TEditProjectDetails,
  ) => {
    setEditSection((prev) => ({ ...prev, [section]: value }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '1rem',
      }}
    >
      {projectDetails && (
        <>
          <WrapperContainer>
            {editSection.overview ? (
              <ProjectEditOverview
                projectDetails={projectDetails}
                onClose={() => changeEditSection(false, 'overview')}
              />
            ) : (
              <ProjectOverview
                projectDetails={projectDetails}
                onClose={() => changeEditSection(true, 'overview')}
              />
            )}
            {editSection.teams ? (
              <ProjectTeamEdit
                projectDetails={projectDetails}
                onClose={() => changeEditSection(false, 'teams')}
              />
            ) : (
              <ProjectTeam
                projectDetails={projectDetails}
                onOpen={() => changeEditSection(true, 'teams')}
              />
            )}
            {editSection.partners ? (
              <ProjectEditPartners
                projectDetails={projectDetails}
                onClose={() => changeEditSection(false, 'partners')}
              />
            ) : (
              <ProjectPartners
                projectDetails={projectDetails}
                onOpen={() => changeEditSection(true, 'partners')}
              />
            )}

            {editSection.social ? (
              <ProjectEditSocials
                projectDetails={projectDetails}
                onClose={() => changeEditSection(false, 'social')}
              />
            ) : (
              <ProjectSocial
                projectDetails={projectDetails}
                onOpen={() => changeEditSection(true, 'social')}
              />
            )}
          </WrapperContainer>
          {downMd ? (
            <ProjectSidebarResponsive projectDetails={projectDetails} />
          ) : (
            <ProjectSidebar projectDetails={projectDetails} />
          )}
        </>
      )}
    </Box>
  );
}

export default ProjectDetails;
