import { Button, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CloseBtn } from '@styles/project/SProjectCreate';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useFormik } from 'formik';
import { flexCentered } from '@styles/shared/SCss';
import { useMoralisFile } from 'react-moralis';
import { FieldStyled } from '@styles/modal/SModal';
import { HeaderProject } from '@styles/project/SProjectSinglePage';
import { TProjectCreateInitialValues } from '@typescript/models/Project.model';

interface Props {
  formik: ReturnType<typeof useFormik<TProjectCreateInitialValues>>;
}

function ProjectCreateHeader({ formik }: Props) {
  const { saveFile } = useMoralisFile();

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setValues,
    setTouched,
  } = formik;

  const handleAddImage = async (event: HTMLInputElement) => {
    const file = event.files?.[0];

    if (file) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });
      if (fileIpfs) {
        setValues((prev) => ({ ...prev, image: fileIpfs._url }));
      }
    }
  };

  const removeImage = () => {
    setValues((prev) => ({ ...prev, image: '' }));
    setTouched({ image: true });
  };

  return (
    <HeaderProject sx={{ position: 'relative', pt: '2.2rem ', px: 0 }}>
      {values.image ? (
        <Box sx={{ position: 'relative' }}>
          <img
            src={values.image}
            style={{ borderRadius: '6px', marginInline: '0.5rem' }}
            width={58}
            height={58}
            alt=""
          />
          <CloseBtn onClick={removeImage} sx={{ padding: '0.1rem' }}>
            <CloseIcon sx={{ width: 15, height: 15 }} />
          </CloseBtn>
        </Box>
      ) : (
        <Button
          component="label"
          htmlFor="projectImage"
          sx={{
            padding: '0',
            marginInline: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Box sx={{ ...flexCentered, flexDirection: 'column' }}>
            <AddPhotoAlternateIcon sx={{ width: 58, height: 58 }} />
            {touched.image && errors.image && (
              <Typography
                variant="h6"
                color="error.main"
                sx={{ fontSize: '12px !important', fontWeight: '400' }}
              >
                Required!
              </Typography>
            )}
          </Box>
          <input
            accept="image/*"
            hidden
            id="projectImage"
            multiple
            type="file"
            onChange={(event) => handleAddImage(event.target)}
            onBlur={handleBlur}
          />
        </Button>
      )}
      <FieldStyled
        id="name"
        name="name"
        label="Name"
        sx={{ minHeight: '90px' }}
        value={values.name}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        helperText={touched.name ? errors.name : ''}
        error={Boolean(errors.name) && touched.name}
      />
      <FieldStyled
        id="symbol"
        name="symbol"
        label="Symbol"
        sx={{ minHeight: '90px', ml: 2 }}
        value={values.symbol}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        helperText={touched.symbol ? errors.symbol : ''}
        error={Boolean(errors.symbol) && touched.symbol}
      />
      <FieldStyled
        id="round"
        name="round"
        label="Round"
        sx={{ minHeight: '90px', ml: 2 }}
        value={values.round}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        helperText={touched.round ? errors.round : ''}
        error={Boolean(errors.round) && touched.round}
      />
    </HeaderProject>
  );
}
export default ProjectCreateHeader;
