import { Box, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { useNavigate } from 'react-router-dom';
import { SeeMoreButton } from '@styles/portfolio/SPortfolio';
import { ProjectTable, ProjectTitle } from '@styles/project/SProjectSinglePage';
import { nameZodWithPagination } from '@typescript/dtos/shared/id.dto';
import { useGetListingsByProjectNameQuery } from '@store/api/listingApi';
import { TProject } from '@typescript/models/Project.model';
import { baseListingsColumns } from '@components/tables-columns/ListingsColumns';
import { NO_LISTINGS_MS } from '@constants/CDefaultMessages';
import DataWrapper from '@components/shared/DataWrapper';
import ProjectListingsTableResponsive from './ProjectListingsTableResponsive';

interface Props {
  project: TProject;
}

function ProjectListingsTable({ project }: Props) {
  const navigate = useNavigate();

  const { breakpoints } = useTheme();

  const down2Lg = useMediaQuery(breakpoints.down('2lg'));

  const {
    data: { count, resource: listings } = { count: 0, resource: [] },
    isLoading,
  } = useGetListingsByProjectNameQuery(
    nameZodWithPagination.parse({
      name: project.name,
      page: 1,
      pageSize: 5,
    }),
  );

  const displayContent = () => {
    if (down2Lg) {
      return <ProjectListingsTableResponsive listings={listings} />;
    }

    return (
      <>
        <ProjectTable>
          <DataGrid
            sx={{ ...DataGridCustomStyles, mt: 0, border: 'none' }}
            rows={listings}
            columns={baseListingsColumns}
            autoHeight
            disableSelectionOnClick
            hideFooter
            loading={isLoading}
            initialState={{
              pagination: { pageSize: 5, page: 0 },
            }}
            paginationMode="server"
            rowCount={count}
            rowsPerPageOptions={[5, 10, 20]}
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
          />
        </ProjectTable>
        <SeeMoreButton fullWidth onClick={() => navigate('/explore')}>
          Explore more
        </SeeMoreButton>
      </>
    );
  };

  return (
    <DataWrapper
      data={listings}
      isLoading={isLoading}
      defaultOptions={{ text: NO_LISTINGS_MS }}
    >
      <Box
        sx={{
          display: down2Lg ? 'flex' : 'block',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ProjectTitle sx={{ mb: '1rem' }}>Project Listings</ProjectTitle>
      </Box>
      {displayContent()}
    </DataWrapper>
  );
}

export default ProjectListingsTable;
