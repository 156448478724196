import { Box, Link, useTheme } from '@mui/material';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { flexCentered } from '@styles/shared/SCss';
import { ResponsiveSidebarItems } from '@constants/CSidebar';
import useUser from '@hooks/useUser';

function SidebarResponsive() {
  const location = useLocation();

  const { currentUser } = useUser();

  const { palette } = useTheme();

  const isActive = (href: string) => location.pathname === href;

  return (
    <Box
      className="Sidebar-Responsive"
      sx={{
        position: 'fixed',
        backgroundColor: palette.background.default,
        bottom: 0,
        zIndex: 5,
        left: 0,
        display: { xs: 'flex', lg: 'flex' },
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1.5rem',
        borderTop: `1px solid ${palette.secondary.light}`,
      }}
    >
      {ResponsiveSidebarItems.map((item) => {
        const Comp = item.icon;
        if (item.isProjected && !currentUser) {
          return null;
        }
        return (
          <Link
            component={ReactLink}
            to={item.href}
            key={item.href}
            aria-label="menu link"
            sx={{
              flex: 1,
              ...flexCentered,
              borderTop: `1px solid ${
                isActive(item.href) ? palette.error.main : 'transparent'
              }`,
              padding: { xs: '1rem 0.5rem', md: '1rem 1.5rem' },
            }}
          >
            <Comp
              style={{
                fill: `${
                  isActive(item.href)
                    ? palette.error.main
                    : palette.primary.main
                }`,
              }}
            />
          </Link>
        );
      })}
    </Box>
  );
}
export default SidebarResponsive;
