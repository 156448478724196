import { Box, Typography } from '@mui/material';
import { TProjectDetails } from '@typescript/TProjectDetails';
import { Link as ScrollLink } from 'react-scroll';
import { ProjectOverviewSidebar } from '@styles/project/SProjectSinglePage';
import { RightSidebarLinks } from '@constants/CSidebar';

interface Props {
  projectDetails: TProjectDetails;
}
function ProjectSidebar({ projectDetails }: Props) {
  const setSidebarDetailsLinks = () =>
    Object.keys(projectDetails).map((objectKey) =>
      RightSidebarLinks.map((sidebarEl) => {
        if (objectKey === sidebarEl.key) {
          return (
            <ScrollLink
              to={sidebarEl.hash.slice(1)}
              key={sidebarEl.key}
              style={{
                color: 'primary.main',
                opacity: 0.5,
                fontWeight: 500,
                fontSize: '0.8rem',
                cursor: 'pointer',
              }}
              activeClass="is-active"
              spy
              smooth
              offset={-100}
            >
              {sidebarEl.text}
            </ScrollLink>
          );
        }
        return null;
      }),
    );
  return (
    <Box position="relative">
      <ProjectOverviewSidebar
        sx={{ position: 'sticky', top: '100px', right: '0', minWidth: '200px' }}
      >
        <Typography variant="h4" sx={{ fontWeight: 700 }} mb={1}>
          Overview
        </Typography>
        {setSidebarDetailsLinks()}
      </ProjectOverviewSidebar>
    </Box>
  );
}

export default ProjectSidebar;
