import { Box } from '@mui/material';
import { TProjectDetails } from '@typescript/TProjectDetails';
import {
  OverviewTitle,
  ProjectPartnersCard,
  ProjectPartnersContainer,
} from '@styles/project/SProjectSinglePage';
import EditIconCom from '@components/shared/EditIconCom';
import useUser from '@hooks/useUser';

interface Props {
  projectDetails: TProjectDetails;
  onOpen: () => void;
}

function ProjectPartners({ projectDetails, onOpen }: Props) {
  const { isAdmin } = useUser();

  if (projectDetails.partnersCards) {
    return (
      <Box id="partners">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <OverviewTitle mb={2} mt={4}>
            Partners & Investors
          </OverviewTitle>

          {isAdmin && (
            <Box>
              <EditIconCom openEditSection={onOpen} />
            </Box>
          )}
        </Box>

        <ProjectPartnersContainer>
          {projectDetails.partnersCards.map((card) => (
            <ProjectPartnersCard key={card.id}>
              <img
                src={card.imageUrl}
                width={100}
                style={{ borderRadius: '6px' }}
                height={100}
                alt=""
              />
            </ProjectPartnersCard>
          ))}
        </ProjectPartnersContainer>
      </Box>
    );
  }

  if (isAdmin && !projectDetails.partnersCards) {
    return (
      <Box id="partners">
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
            <EditIconCom openEditSection={onOpen} />
          </Box>
          <OverviewTitle mb={2} mt={4}>
            Partners & Investors
          </OverviewTitle>
        </Box>
      </Box>
    );
  }

  return null;
}

export default ProjectPartners;
