import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {
  CardTitle,
  ProjectDetailsCard,
} from '@styles/project/SProjectSinglePage';
import { ProjectCreateDetails, TextArea } from '@styles/project/SProjectCreate';
import { CloseBtn, FieldStyled } from '@styles/modal/SModal';
import { calculate, formatModalDecimals, formatNr } from '@utils/functions';
import { ProjectStatus } from '@constants/CProject';
import { useState } from 'react';
import CloseIconCom from '@components/shared/CloseIconCom';
import { useMoralisFile } from 'react-moralis';
import { flexCentered } from '@styles/shared/SCss';
import { TProjectCreateInitialValues } from '@typescript/models/Project.model';

interface Props {
  formik: ReturnType<typeof useFormik<TProjectCreateInitialValues>>;
}

function ProjectCreateValues({ formik }: Props) {
  const [isPublic, setIsPublic] = useState(true);

  const [isLegionProject, setIsLegionProject] = useState(false);

  const { saveFile } = useMoralisFile();

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setValues,
    setTouched,
  } = formik;

  const handleFundraisingTarget = (newTarget: string) => {
    const newTargetFormatted = formatModalDecimals(newTarget);
    setTouched({ ...touched, fundraisingTarget: true, totalTokens: true });

    if (newTarget !== '' && values.tokenPrice !== '') {
      const newTotalTokens = calculate(
        +newTargetFormatted,
        '/',
        +values.tokenPrice,
      );

      setValues((prev) => ({
        ...prev,
        fundraisingTarget: newTargetFormatted,
        totalTokens: +formatModalDecimals(newTotalTokens.toString()),
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        fundraisingTarget: newTarget === '' ? '' : newTargetFormatted,
      }));
    }
  };
  const handleMinInvestment = (newTarget: string) => {
    setTouched({ ...touched, minInvestment: true });
    const newMinInvestment = formatModalDecimals(newTarget);

    setValues((prev) => ({
      ...prev,
      minInvestment: newMinInvestment,
    }));
  };

  const handleMaxInvestment = (newTarget: string) => {
    setTouched({ ...touched, maxInvestment: true });
    const newMaxInvestment = formatModalDecimals(newTarget);

    setValues((prev) => ({
      ...prev,
      maxInvestment: newMaxInvestment,
    }));
  };

  const handleChangeFeeInPercentage = (percentage: string) => {
    setTouched({ ...touched, feeInPercentage: true });
    const percentageFormatted =
      percentage === '' ? '' : formatModalDecimals(percentage);

    setValues((prev) => ({
      ...prev,
      feeInPercentage: percentageFormatted,
    }));
  };

  const handleChangeFeeInPercentagePrivate = (percentage: string) => {
    setTouched({ ...touched, feeInPercentagePrivate: true });
    const percentageFormatted =
      percentage === '' ? '' : formatModalDecimals(percentage);

    setValues((prev) => ({
      ...prev,
      feeInPercentagePrivate: percentageFormatted,
    }));
  };

  const handleTokenPrice = (newTokenPrice: string) => {
    const newTokenPriceFormatted = formatModalDecimals(
      newTokenPrice.toString(),
    );

    if (newTokenPrice !== '' && values.fundraisingTarget !== '') {
      const newTotalTokens = calculate(
        +values.fundraisingTarget,
        '/',
        +newTokenPriceFormatted,
      );

      setValues((prev) => ({
        ...prev,
        tokenPrice: newTokenPriceFormatted,
        totalTokens: +formatModalDecimals(newTotalTokens.toString()),
      }));
      setTouched({
        ...touched,
        tokenPrice: true,
        totalTokens: true,
      });
    } else {
      setValues((prev) => ({
        ...prev,
        tokenPrice: newTokenPrice === '' ? '' : newTokenPriceFormatted,
      }));
      setTouched({
        ...touched,
        tokenPrice: true,
      });
    }
  };

  const handleChangeIsPublic = (newIsPublic: boolean) => {
    setTouched({
      ...touched,
      status: true,
    });
    setIsPublic(newIsPublic);
    setValues((prev) => ({
      ...prev,
      status: newIsPublic ? ProjectStatus.LIVE : ProjectStatus.LIVE_PRIVATE,
    }));
  };

  const handleChangeIsLegionProject = (newValue: boolean) => {
    setTouched({
      ...touched,
      isLegionProject: true,
    });
    setIsLegionProject(newValue);
    setValues((prev) => ({
      ...prev,
      isLegionProject: newValue,
    }));
  };

  const removeNetworkImage = () => {
    setValues((prev) => ({ ...prev, networkImage: '' }));
    setTouched({ networkImage: true });
  };

  const handleAddNetworkImage = async (event: HTMLInputElement) => {
    const file = event.files?.[0];

    if (file) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });
      if (fileIpfs) {
        setValues((prev) => ({ ...prev, networkImage: fileIpfs._url }));
      }
    }
  };

  return (
    <ProjectCreateDetails sx={{ pt: 2, borderBottom: 'none', pb: 0 }}>
      <ProjectDetailsCard sx={{ height: 'unset' }}>
        <CardTitle sx={{ marginBottom: '1.3rem' }}>Token Details</CardTitle>
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', mt: 4 }}>
          <FieldStyled
            id="fundraisingTarget"
            name="fundraisingTarget"
            label="Fundraising target"
            sx={{ minHeight: '80px' }}
            value={values.fundraisingTarget}
            onBlur={handleBlur}
            onChange={(event) => handleFundraisingTarget(event.target.value)}
            type="number"
            helperText={
              touched.fundraisingTarget ? errors.fundraisingTarget : ''
            }
            error={
              Boolean(errors.fundraisingTarget) && touched.fundraisingTarget
            }
          />
          <FieldStyled
            id="tokenPrice"
            name="tokenPrice"
            label="Token Price"
            sx={{ minHeight: '80px' }}
            value={values.tokenPrice}
            onBlur={handleBlur}
            onChange={(event) => handleTokenPrice(event.target.value)}
            type="number"
            helperText={touched.tokenPrice ? errors.tokenPrice : ''}
            error={Boolean(errors.tokenPrice) && touched.tokenPrice}
          />
          <FieldStyled
            id="minInvestment"
            name="minInvestment"
            label="Min Investment"
            sx={{ minHeight: '80px' }}
            value={values.minInvestment}
            onBlur={handleBlur}
            onChange={(event) => handleMinInvestment(event.target.value)}
            type="number"
            helperText={touched.minInvestment ? errors.minInvestment : ''}
            error={Boolean(errors.minInvestment) && touched.minInvestment}
          />
          <FieldStyled
            id="maxInvestment"
            name="maxInvestment"
            label="Max Investment"
            sx={{ minHeight: '80px' }}
            value={values.maxInvestment}
            onBlur={handleBlur}
            onChange={(event) => handleMaxInvestment(event.target.value)}
            type="number"
            helperText={touched.maxInvestment ? errors.maxInvestment : ''}
            error={Boolean(errors.maxInvestment) && touched.maxInvestment}
          />
          <Box sx={{ display: 'flex', ml: '16px' }}>
            <Typography
              variant="h6"
              sx={{ fontSize: '14px', opacity: 0.5 }}
              mr={1}
            >
              Total Tokens:
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '14px' }} mr={1}>
              {formatNr(values.totalTokens)}
            </Typography>
          </Box>
        </Box>
      </ProjectDetailsCard>
      <ProjectDetailsCard sx={{ height: 'unset' }}>
        <CardTitle sx={{ marginBottom: '1.3rem' }}>Admin details</CardTitle>
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', mt: 4 }}>
          <FieldStyled
            id="feeInPercentage"
            name="feeInPercentage"
            label="Fee In Percentage"
            sx={{ minHeight: '80px' }}
            value={values.feeInPercentage}
            onBlur={handleBlur}
            onChange={(event) =>
              handleChangeFeeInPercentage(event.target.value)
            }
            type="number"
            helperText={touched.feeInPercentage ? errors.feeInPercentage : ''}
            error={Boolean(errors.feeInPercentage) && touched.feeInPercentage}
          />
          <FieldStyled
            id="feeInPercentagePrivate"
            name="feeInPercentagePrivate"
            label="Fee In Percentage Private"
            sx={{ minHeight: '80px' }}
            value={values.feeInPercentagePrivate}
            onBlur={handleBlur}
            onChange={(event) =>
              handleChangeFeeInPercentagePrivate(event.target.value)
            }
            type="number"
            helperText={
              touched.feeInPercentagePrivate
                ? errors.feeInPercentagePrivate
                : ''
            }
            error={
              Boolean(errors.feeInPercentagePrivate) &&
              touched.feeInPercentagePrivate
            }
          />
          <FieldStyled
            id="lvWallet"
            name="lvWallet"
            label="LV wallet"
            sx={{ minHeight: '80px' }}
            value={values.lvWallet}
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            helperText={touched.lvWallet ? errors.lvWallet : ''}
            error={Boolean(errors.lvWallet) && touched.lvWallet}
          />
          <ToggleButtonGroup
            value={isPublic}
            exclusive
            onChange={(_, newValue) => handleChangeIsPublic(newValue)}
            aria-label="isPublic"
          >
            <ToggleButton value aria-label="public">
              Public
            </ToggleButton>
            <ToggleButton value={false} aria-label="private">
              Private
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={isLegionProject}
            exclusive
            onChange={(_, newValue) => handleChangeIsLegionProject(newValue)}
            aria-label="isEthProject"
          >
            <ToggleButton value={false}>Normal Project</ToggleButton>
            <ToggleButton value>Legion Project</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </ProjectDetailsCard>
      <ProjectDetailsCard sx={{ height: 'unset' }}>
        <CardTitle sx={{ marginBottom: '1.3rem' }}>Vesting Details</CardTitle>
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', mt: 4 }}>
          <TextArea
            id="vestingDetails"
            sx={{ maxHeight: '300px' }}
            placeholder="Vesting Details.."
            value={values.vestingDetails}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography
            sx={{
              visibility:
                errors.vestingDetails && touched.vestingDetails
                  ? 'initial'
                  : 'hidden',
              height: 20,
            }}
            variant="h6"
            color="error"
            mt="0.1rem"
            ml={1}
          >
            {errors.vestingDetails || ''}
          </Typography>
        </Box>
      </ProjectDetailsCard>
      <ProjectDetailsCard sx={{ gridArea: 'div2', height: '270px' }}>
        <CardTitle sx={{ marginBottom: '1.3rem' }}>Network</CardTitle>
        <Box sx={{ display: 'flex', gap: 1.5, flexDirection: 'column', mt: 4 }}>
          <FieldStyled
            id="chain"
            name="chain"
            label="Chain"
            sx={{ minHeight: '90px' }}
            value={values.chain}
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            helperText={touched.chain ? errors.chain : ''}
            error={Boolean(errors.chain) && touched.chain}
          />
          <Box>
            <CardTitle sx={{ mb: 0 }}>Network Image</CardTitle>
            {values.networkImage ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <img
                  src={values.networkImage}
                  style={{
                    borderRadius: '6px',
                    marginInline: '0.5rem',
                  }}
                  width={44}
                  height={44}
                  alt=""
                />
                <CloseBtn
                  onClick={removeNetworkImage}
                  sx={{ padding: '0.1rem' }}
                >
                  <CloseIconCom sx={{ width: 15, height: 15 }} />
                </CloseBtn>
              </Box>
            ) : (
              <Button
                component="label"
                htmlFor="networkImage"
                sx={{
                  padding: '0',
                  marginInline: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Box sx={{ ...flexCentered, flexDirection: 'column' }}>
                  <AddPhotoAlternateIcon sx={{ width: 44, height: 44 }} />
                  {touched.networkImage && errors.networkImage && (
                    <Typography
                      variant="h6"
                      color="error.main"
                      sx={{ fontSize: '12px !important', fontWeight: '400' }}
                    >
                      Required!
                    </Typography>
                  )}
                </Box>
                <input
                  accept="image/*"
                  hidden
                  id="networkImage"
                  multiple
                  type="file"
                  onChange={(event) => handleAddNetworkImage(event.target)}
                  onBlur={handleBlur}
                />
              </Button>
            )}
          </Box>
        </Box>
      </ProjectDetailsCard>
    </ProjectCreateDetails>
  );
}

export default ProjectCreateValues;
