import { Box } from '@mui/material';
import { TOffersTab } from '@typescript/TTabs';
import { OffersContentType } from '@constants/CContentType';
import { OfferAction } from '@constants/CDataActions';
import { TOffer } from '@typescript/models/Offer.model';
import ActiveOffersResponsive from './ActiveOffersResponsive';
import InactiveOffersResponsive from './InactiveOffersResponsive';

interface Props {
  tab: TOffersTab;
  changeAction: (newOffer: TOffer, newAction: OfferAction) => void;
}

function OffersTableResponsive({ tab, changeAction }: Props) {
  const tableContentResponsive = () => {
    switch (tab.index) {
      case OffersContentType.INACTIVE: {
        return <InactiveOffersResponsive />;
      }
      default: {
        return <ActiveOffersResponsive changeAction={changeAction} />;
      }
    }
  };
  return (
    <Box sx={{ mt: '0.5rem', height: '100%' }}>{tableContentResponsive()}</Box>
  );
}

export default OffersTableResponsive;
