import { TChildren } from '@typescript/TGlobal';
import Lottie from 'lottie-react';
import LogoLoadingAnimation from '@animations/lv-loading-animation.json';
import { useEffect } from 'react';

interface Props {
  children: TChildren;
  isFetching: boolean;
}

function DataGridWrapper({ children, isFetching }: Props) {
  useEffect(() => {
    const buttonContainer = document.querySelector('.button-container');
    if (buttonContainer) {
      const buttons = buttonContainer.querySelectorAll('button');
      buttons.forEach((button) => {
        button.disabled = isFetching;
      });
    }
  }, [isFetching]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: isFetching ? 'block' : 'none',
          width: 100,
          height: 100,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50% , -50%)',
        }}
      >
        <Lottie
          animationData={LogoLoadingAnimation}
          loop
          width={40}
          height={40}
        />
      </div>
      <div
        className="button-container"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          background: 'transparent',
          border: 'none',
          padding: 0,
          margin: 0,
          opacity: isFetching ? 0.5 : 1,
          cursor: isFetching ? 'not-allowed' : 'default',
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default DataGridWrapper;
