import { Box, Typography, BoxProps } from '@mui/material';
import Lottie from 'lottie-react';
import LogoLoadingAnimation from '@animations/lv-loading-animation.json';
import { toRem } from '@utils/functions';

type Dimension = 'contained' | 'medium' | 'sm' | 'sx';

export interface LoadingPageProps {
  dimension?: Dimension;
  description?: string;
  children?: JSX.Element | JSX.Element[];
  width?: string;
  sx?: BoxProps;
}

function IsLoadingCom({
  dimension,
  description,
  children,
  width,
  sx,
}: LoadingPageProps) {
  let height;

  switch (dimension) {
    case 'contained':
      height = 'auto';
      break;
    case 'medium':
      height = '22vh';
      break;
    case 'sm':
      height = '10vh';
      break;
    case 'sx':
      height = '5vh';
      break;
    default:
      height = '100vh';
      break;
  }

  return (
    <Box
      sx={{
        width: width || '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        lineHeight: '1.5',
        height,
        ...sx,
        '& > div': {
          display: 'flex',
        },
      }}
    >
      <Lottie animationData={LogoLoadingAnimation} loop />
      {description && (
        <Typography
          sx={{ fontSize: { md: toRem(24), xs: toRem(20) }, mt: 4 }}
          variant="h3"
        >
          {description}
        </Typography>
      )}
      {children && children}
    </Box>
  );
}
export default IsLoadingCom;
