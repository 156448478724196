import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useStyles from '@hooks/useStyles';
import { TTransaction } from '@typescript/models/TradeTransaction.model';
import { TimeCell, WalletCell } from '@components/tables-columns/TableCell';
import { BuyGradientDark } from '@styles/shared/SButtons';

interface Props {
  transactions: TTransaction[];
  isLoading: boolean;
  actionSeeMore: () => void;
  count: number;
}

function ProjectTransactionsResponsive({
  transactions,
  isLoading,
  actionSeeMore,
  count,
}: Props) {
  const navigate = useNavigate();

  const { border1pxSecondaryLight } = useStyles();

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '0.7rem',
        maxWidth: { xs: '450px', md: 'unset' },
        marginInline: 'auto',
      }}
    >
      {transactions.map((transaction) => (
        <Box
          key={transaction.id}
          sx={{
            borderRadius: '6px',
            border: border1pxSecondaryLight,
          }}
        >
          <Box
            sx={{
              borderBottom: border1pxSecondaryLight,
              borderRadius: '4px 4px 0 0',
              p: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={transaction.project.image}
              style={{ borderRadius: '6px', width: 48, height: 48 }}
              alt=""
            />
            <Typography
              sx={{
                pl: 1,
                fontWeight: 400,
                fontSize: '17px',
                textDecoration: 'underline',
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'none',
                },
              }}
              onClick={() => navigate(`/projects/${transaction.project.slug}`)}
            >
              {transaction.project.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: 'grid', md: 'flex' },
              gridTemplateColumns: { xs: '1fr 1fr' },
            }}
          >
            <Box
              sx={{
                p: 3,
                borderBottom: border1pxSecondaryLight,
                borderRight: border1pxSecondaryLight,
              }}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
              >
                Event
              </Typography>
              <Typography mt={1}>{transaction.tradeType}</Typography>
            </Box>
            <Box
              sx={{
                p: 3,
                borderBottom: border1pxSecondaryLight,
                borderRight: { md: border1pxSecondaryLight },
              }}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
              >
                Price
              </Typography>
              <Typography mt={1}>${transaction.value}</Typography>
            </Box>
            <Box
              sx={{
                p: 3,
                borderBottom: border1pxSecondaryLight,
                borderRight: border1pxSecondaryLight,
              }}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
              >
                Quantity
              </Typography>
              <Typography mt={1}>{transaction.tokenAmount}</Typography>
            </Box>
            <Box
              sx={{
                p: 3,
                borderBottom: border1pxSecondaryLight,
                borderRight: { md: border1pxSecondaryLight },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  opacity: 0.5,
                  mb: 1,
                }}
              >
                From
              </Typography>
              <WalletCell wallet={transaction.from.ethAddress} />
            </Box>
            <Box sx={{ p: 3, borderRight: border1pxSecondaryLight }}>
              <Typography
                sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5, mb: 1 }}
              >
                To
              </Typography>
              <WalletCell wallet={transaction.to.ethAddress} />
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography
                sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5, mb: 1 }}
              >
                Time
              </Typography>
              <TimeCell
                hash={transaction.hash}
                createdAt={transaction.createdAt}
              />
            </Box>
          </Box>
        </Box>
      ))}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: count === transactions.length ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ maxWidth: '200px' }}>
            <BuyGradientDark onClick={actionSeeMore}>See More</BuyGradientDark>
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default ProjectTransactionsResponsive;
