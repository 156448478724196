import { Box, CircularProgress } from '@mui/material';
import { CardsContainer } from '@styles/portfolio/SPortfolio';
import { BuyGradientDark } from '@styles/shared/SButtons';
import { useEffect, useState } from 'react';
import DataWrapper from '@components/shared/DataWrapper';
import { Svgs } from '@utils/svgs';
import { NO_ALLOCATIONS_MS } from '@constants/CDefaultMessages';
import { useGetEthAllocationsQuery } from '@store/api/ethAllocationApi';
import EthAllocationCard from './EthAllocationCard';

function EthAllocationsView() {
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(10);

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const {
    data: { resource: myEthAllocations, count } = { resource: [], count: 0 },
    isLoading,
    isFetching,
  } = useGetEthAllocationsQuery({ page: page + 1, pageSize });

  const loadMore = () => {
    setPage(0);
    setPageSize(pageSize + 5);
    setIsLoadingMore(true);
  };

  useEffect(() => {
    if (isLoadingMore && !isFetching) {
      setIsLoadingMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <DataWrapper
      isLoading={isLoading}
      isLoadingOptions={{ dimension: 'contained' }}
      data={myEthAllocations}
      defaultOptions={{
        icon: Svgs.EmptyProjects,
        text: NO_ALLOCATIONS_MS,
      }}
    >
      <Box sx={{ mx: 2 }}>
        <CardsContainer>
          {myEthAllocations.map((ethAllocation) => (
            <EthAllocationCard
              key={ethAllocation.id}
              ethAllocation={ethAllocation}
            />
          ))}
        </CardsContainer>
        {isLoadingMore ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: count === myEthAllocations.length ? 'none' : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <BuyGradientDark onClick={loadMore}>See More</BuyGradientDark>
            </Box>
          </Box>
        )}
      </Box>
    </DataWrapper>
  );
}

export default EthAllocationsView;
