import { Box } from '@mui/material';

import { ProjectStatus } from '@constants/CProject';
import { TProject } from '@typescript/models/Project.model';
import useUser from '@hooks/useUser';
import ProjectTransactions from './ProjectTransactions';
import ProjectInvestmentTxTable from './ProjectInvestmentTxTable';

interface Props {
  project: TProject;
}

function ProjectTransactionsTable({ project }: Props) {
  const { currentUser } = useUser();

  if (project && project.id) {
    switch (project.status) {
      case ProjectStatus.LIVE:
        return <ProjectInvestmentTxTable projectId={project.id} />;
      case ProjectStatus.LIVE_PRIVATE:
        return <ProjectInvestmentTxTable projectId={project.id} />;
      default:
        if (currentUser) {
          return <ProjectTransactions projectId={project.id} />;
        }
        return null;
    }
  }

  return <Box />;
}

export default ProjectTransactionsTable;
