import { TOffersTab } from '@typescript/TTabs';
import { OffersContentType } from '@constants/CContentType';
import { Box } from '@mui/material';
import { TOffer } from '@typescript/models/Offer.model';
import { OfferAction } from '@constants/CDataActions';
import InactiveOffers from './InactiveOffers';
import ActiveOffers from './ActiveOffers';

interface Props {
  tab: TOffersTab;
  changeAction: (newOffer: TOffer, newAction: OfferAction) => void;
}

function OffersTable({ tab, changeAction }: Props) {
  const tableContent = () => {
    switch (tab.index) {
      case OffersContentType.INACTIVE: {
        return <InactiveOffers />;
      }
      default: {
        return <ActiveOffers changeAction={changeAction} />;
      }
    }
  };
  return <Box sx={{ mt: '0.5rem', height: '100%' }}>{tableContent()}</Box>;
}

export default OffersTable;
