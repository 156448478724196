import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import uuid from 'react-uuid';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useProjectDetails from '@hooks/useProjectDetails';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { TPartnersObj, TProjectDetails } from '@typescript/TProjectDetails';
import { useMoralisFile } from 'react-moralis';
import { EditBtnStyle } from '@styles/project/SProjectEdit';
import {
  ProjectPartnersCard,
  ProjectPartnersContainer,
} from '@styles/project/SProjectSinglePage';
import { flexCentered } from '@styles/shared/SCss';
import { AddPartnerBox } from '@styles/project/SProjectCreate';
import useNotification from '@hooks/useNotification';

interface Props {
  onClose: () => void;
  projectDetails: TProjectDetails;
}

function ProjectEditPartners({ onClose, projectDetails }: Props) {
  const { notifyError, notifySuccess } = useNotification();

  const { updateProjectDetails } = useProjectDetails();

  const { saveFile } = useMoralisFile();

  const [allPartnersCards, setAllPartnersCards] = useState<TPartnersObj[]>(
    projectDetails.partnersCards || ([] as TPartnersObj[]),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addPartnerImage = async (target: HTMLInputElement) => {
    const file = target?.files?.[0];
    if (file) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });

      const url = fileIpfs?._url;

      if (!url) {
        throw Error(`No url found for ${target?.files?.[0]}`);
      }

      setAllPartnersCards((prev) => [
        ...prev,
        {
          id: uuid(),
          imageUrl: url,
        },
      ]);
      notifySuccess('Partner added successfully!');
    }
  };

  const removePartner = (id: string) => {
    setAllPartnersCards((prev) => prev.filter((partner) => partner.id !== id));
  };

  const handleUpdatePartners = async () => {
    if (allPartnersCards.length > 0) {
      try {
        setIsLoading(true);

        await updateProjectDetails({
          id: projectDetails.id,
          partnersCards: allPartnersCards,
        });
        notifySuccess('Project partners updated!');
      } catch (error) {
        notifyError('Project partners update failed!');
        throw error;
      } finally {
        setIsLoading(false);
        onClose();
      }
    }
  };

  if (isLoading) return <IsLoadingCom dimension="medium" />;
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'end', my: 2, gap: 1 }}>
        <Button onClick={handleUpdatePartners} variant="outlined">
          Save
        </Button>
        <Button onClick={onClose} sx={EditBtnStyle}>
          <CloseIcon sx={{ width: 20, height: 20 }} />
        </Button>
      </Box>
      {allPartnersCards.length === 0 && (
        <Typography variant="h6" color="error" mb={1}>
          Required at least one team card!
        </Typography>
      )}
      <ProjectPartnersContainer>
        {allPartnersCards.map((partner) => (
          <ProjectPartnersCard
            key={partner.id}
            sx={{ padding: '1rem', flexDirection: 'column' }}
          >
            <Button
              onClick={() => removePartner(partner.id)}
              sx={{
                ...EditBtnStyle,
                alignSelf: 'end',
              }}
            >
              <CloseIcon sx={{ width: 20, height: 20 }} />
            </Button>
            <Box sx={flexCentered}>
              <img
                src={partner.imageUrl}
                width={100}
                style={{ borderRadius: '6px' }}
                height={100}
                alt=""
              />
            </Box>
          </ProjectPartnersCard>
        ))}
        <AddPartnerBox>
          <label
            htmlFor="partnerImage"
            aria-label="Partners Image"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              minHeight: '125px',
            }}
          >
            <input
              accept="image/*"
              hidden
              id="partnerImage"
              multiple
              type="file"
              onChange={(event) => addPartnerImage(event?.target)}
            />
            <AddOutlinedIcon sx={{ width: 60, height: 60, opacity: 0.5 }} />
          </label>
        </AddPartnerBox>
      </ProjectPartnersContainer>
    </>
  );
}
export default ProjectEditPartners;
