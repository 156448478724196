import CardWrapper from '@components/shared/CardWrapper';
import DataWrapper from '@components/shared/DataWrapper';
import {
  OfferStatusCell,
  askingPriceCell,
  currentTokenPriceCell,
  offerPriceCell,
  tokenAmountCell,
  totalCostCell,
} from '@components/tables-columns/TableCell';
import { NO_OFFERS_MS } from '@constants/CDefaultMessages';
import useStyles from '@hooks/useStyles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useGetMyInactiveOffersQuery } from '@store/api/offerApi';
import { BuyGradientDark } from '@styles/shared/SButtons';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import { Svgs } from '@utils/svgs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function InactiveOffersResponsive() {
  const [pageSize, setPageSize] = useState<number>(20);

  const {
    data: { resource: offers, count } = {
      resource: [],
      count: 0,
    },
    isLoading,
    isFetching,
  } = useGetMyInactiveOffersQuery(paginationZod.parse({ page: 1, pageSize }));

  const { border1pxSecondaryLight } = useStyles();

  const navigate = useNavigate();

  const actionSeeMore = () => {
    setPageSize(pageSize + 5);
  };

  return (
    <DataWrapper
      isLoading={isLoading}
      data={offers}
      defaultOptions={{
        icon: Svgs.IconTransactions,
        text: NO_OFFERS_MS,
        fullHeight: true,
      }}
      isLoadingOptions={{ sx: { height: '100%' } }}
    >
      <Box
        sx={{
          pb: '10rem',
          display: 'grid',
          gap: '0.7rem',
        }}
      >
        {offers.map((item) => (
          <CardWrapper isFetching={isFetching} key={item.id}>
            <Box
              sx={{
                width: '100%',
                borderRadius: '6px',
                border: border1pxSecondaryLight,
                backgroundColor: 'secondary.main',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#17172B',
                  borderBottom: border1pxSecondaryLight,
                  borderRadius: '4px 4px 0 0',
                  py: 2,
                  px: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <img
                    src={item.listing.project.image}
                    style={{ borderRadius: '6px', width: 48, height: 48 }}
                    alt=""
                  />
                  <Typography
                    sx={{
                      pl: 1,
                      fontWeight: 400,
                      fontSize: '17px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'none',
                      },
                    }}
                    onClick={() =>
                      navigate(`/projects/${item.listing.project.slug}`)
                    }
                  >
                    {item.listing.project.name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: 'grid', md: 'flex' },
                  gridTemplateColumns: { xs: '1fr 1fr' },
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Current Token Price
                  </Typography>
                  {
                    currentTokenPriceCell(item.listing.project.currentPrice)
                      .renderCell
                  }
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { xs: border1pxSecondaryLight, md: 'none' },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Listing Asking Price
                  </Typography>
                  {askingPriceCell(item.listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Listing Token Amount
                  </Typography>
                  {tokenAmountCell(item.listing.amount).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Listing Total Cost
                  </Typography>
                  {totalCostCell(item.listing.price).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Offer Price
                  </Typography>
                  {offerPriceCell(item).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Total Offer Cost
                  </Typography>
                  {totalCostCell(item.offeredPrice).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Status
                  </Typography>
                  <OfferStatusCell offer={item} inactive />
                </Box>
              </Box>
            </Box>
          </CardWrapper>
        ))}
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: count === offers.length ? 'none' : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BuyGradientDark onClick={actionSeeMore}>See More</BuyGradientDark>
          </Box>
        )}
      </Box>
    </DataWrapper>
  );
}

export default InactiveOffersResponsive;
