import { Box, Typography } from '@mui/material';
import {
  CardTitle,
  ProjectDetails,
  ProjectDetailsCard,
} from '@styles/project/SProjectSinglePage';
import EditIconCom from '@components/shared/EditIconCom';
import { calculate, formatNr } from '@utils/functions';
import { ProjectStatus } from '@constants/CProject';
import useUser from '@hooks/useUser';
import { TEditSection, TProject } from '@typescript/models/Project.model';
import ProjectStatusCom from '../helpers/ProjectStatusCom';

interface Props {
  project: TProject;
  changeEditSection: (value: boolean, section: keyof TEditSection) => void;
}

function ProjectValues({ project, changeEditSection }: Props) {
  const { isAdmin } = useUser();

  const { label, progressBar, progress } = ProjectStatusCom({ project });

  const isLiveProject =
    project.status === ProjectStatus.LIVE ||
    project.status === ProjectStatus.LIVE_PRIVATE;

  return (
    <Box sx={{ paddingTop: '1.5rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2 }}>
        {isAdmin && (
          <EditIconCom
            openEditSection={() => changeEditSection(true, 'values')}
          />
        )}
      </Box>
      <ProjectDetails>
        <ProjectDetailsCard>
          <CardTitle>Project Status</CardTitle>
          {label}
        </ProjectDetailsCard>
        <ProjectDetailsCard>
          <CardTitle>Tokens Purchased</CardTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <img
              src={project.image}
              style={{ borderRadius: '4px', marginRight: '0.5rem' }}
              width={25}
              height={25}
              alt=""
            />
            <Typography variant="h6">
              {formatNr(
                calculate(project.investmentAmount, '/', project.tokenPrice),
              )}{' '}
              {project.symbol}
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ opacity: 0.5 }}>
            {formatNr(project.investmentAmount, true)} USDT
          </Typography>
        </ProjectDetailsCard>
        <ProjectDetailsCard>
          {isLiveProject ? (
            <CardTitle sx={{ marginBottom: '1.3rem' }}>
              Fundraising Target
            </CardTitle>
          ) : (
            <CardTitle sx={{ marginBottom: '1.3rem' }}>
              Vesting Progress
            </CardTitle>
          )}
          {progressBar}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1.3rem',
            }}
          >
            <Typography variant="h6" sx={{ opacity: 0.5 }}>
              {progress} %
            </Typography>
            {isLiveProject ? (
              <Typography sx={{ opacity: 0.5 }} variant="h6">
                {formatNr(project.investmentAmount)}/
                {formatNr(project.fundraisingTarget)}
              </Typography>
            ) : (
              <Typography sx={{ opacity: 0.5 }} variant="h6">
                {formatNr(project.totalUnlockedTokens)}/
                {formatNr(project.totalTokens)}
              </Typography>
            )}
          </Box>
        </ProjectDetailsCard>
      </ProjectDetails>
    </Box>
  );
}

export default ProjectValues;
