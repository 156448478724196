import { useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { ProjectTable } from '@styles/project/SProjectSinglePage';
import DataWrapper from '@components/shared/DataWrapper';
import { useGetTransactionInvestmentByProjectIdQuery } from '@store/api/transactionInvestmentApi';
import { idWithPaginationZod } from '@typescript/dtos/shared/id.dto';
import { baseInvestmentTxColumns } from '@components/tables-columns/InvestmentTxColumns';
import { memo, useState } from 'react';
import TransactionsInvestmentResponsive from './ProjectTransactionInvestmentResponsive';

interface Props {
  projectId: string;
}

function ProjectInvestmentTxTable({ projectId }: Props) {
  const { breakpoints } = useTheme();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const down2Lg = useMediaQuery(breakpoints.down('2lg'));

  const {
    data: { count, resource: txInvestment } = { count: 0, resource: [] },
    isLoading: isFetchingTx,
  } = useGetTransactionInvestmentByProjectIdQuery(
    idWithPaginationZod.parse({
      page: page + 1,
      pageSize: down2Lg ? pageSize : 5,
      id: projectId,
    }),
  );

  const displayContent = () => {
    if (down2Lg) {
      return (
        <TransactionsInvestmentResponsive
          transactionInvestment={txInvestment}
          count={count}
          isLoading={isFetchingTx}
          actionSeeMore={() => {
            setPage(0);
            setPageSize(pageSize + 5);
          }}
        />
      );
    }
    return (
      <ProjectTable>
        <DataGrid
          sx={{ ...DataGridCustomStyles, mt: 0, border: 'none' }}
          rows={txInvestment}
          columns={baseInvestmentTxColumns}
          autoHeight
          disableSelectionOnClick
          rowCount={count}
          initialState={{
            pagination: { pageSize: 5, page },
          }}
          pagination
          rowsPerPageOptions={[5]}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
        />
      </ProjectTable>
    );
  };

  return (
    <DataWrapper data={txInvestment} isLoading={isFetchingTx}>
      {displayContent()}
    </DataWrapper>
  );
}

export default memo(ProjectInvestmentTxTable);
