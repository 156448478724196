import { Box, Button, Typography } from '@mui/material';
import uuid from 'react-uuid';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { TProjectCreateDetailsInitialValues } from '@typescript/TProjectDetails';
import { useMoralisFile } from 'react-moralis';
import { EditBtnStyle } from '@styles/project/SProjectEdit';
import {
  OverviewTitle,
  ProjectPartnersCard,
  ProjectPartnersContainer,
} from '@styles/project/SProjectSinglePage';
import { flexCentered } from '@styles/shared/SCss';
import { AddPartnerBox } from '@styles/project/SProjectCreate';
import { useFormik } from 'formik';
import useNotification from '@hooks/useNotification';

interface Props {
  formik: ReturnType<typeof useFormik<TProjectCreateDetailsInitialValues>>;
}

function ProjectCreatePartners({ formik: ctxFormik }: Props) {
  const {
    values: ctxValues,
    setValues: setCtxValues,
    setTouched: setCtxTouched,
    touched: ctxTouched,
    errors: ctxErrors,
  } = ctxFormik;

  const { notifySuccess } = useNotification();

  const { saveFile } = useMoralisFile();

  const addPartner = async (target: HTMLInputElement) => {
    setCtxTouched({ partnersCardsTouched: true });
    const file = target?.files?.[0];
    if (file) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });

      const url = fileIpfs?._url;

      if (!url) {
        throw Error(`No url found for ${target?.files?.[0]}`);
      }

      setCtxValues((prev) => ({
        ...prev,
        partnersCards: [
          {
            id: uuid(),
            imageUrl: url,
          },
          ...prev.partnersCards,
        ],
      }));

      notifySuccess('Partner added successfully!');
    }
  };

  const removePartner = (id: string) => {
    setCtxTouched({ partnersCardsTouched: true });
    setCtxValues((prev) => ({
      ...prev,
      partnersCards: prev.partnersCards.filter((partner) => partner.id !== id),
    }));
  };

  return (
    <>
      <OverviewTitle mb={2} mt={4}>
        Partners
      </OverviewTitle>
      {ctxErrors.partnersCards && ctxTouched.partnersCardsTouched && (
        <Typography variant="h6" color="error" mb={1}>
          {ctxErrors.partnersCards as string}
        </Typography>
      )}
      <ProjectPartnersContainer>
        {ctxValues.partnersCards.map((partner) => (
          <ProjectPartnersCard
            key={partner.id}
            sx={{ padding: '1rem', flexDirection: 'column' }}
          >
            <Button
              onClick={() => removePartner(partner.id)}
              sx={{
                ...EditBtnStyle,
                alignSelf: 'end',
              }}
            >
              <CloseIcon sx={{ width: 20, height: 20 }} />
            </Button>
            <Box sx={flexCentered}>
              <img
                src={partner.imageUrl}
                width={100}
                style={{ borderRadius: '6px' }}
                height={100}
                alt=""
              />
            </Box>
          </ProjectPartnersCard>
        ))}
        <AddPartnerBox>
          <label
            htmlFor="partnerImage"
            aria-label="Partners Image"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              minHeight: '125px',
            }}
          >
            <input
              accept="image/*"
              hidden
              id="partnerImage"
              multiple
              type="file"
              onChange={(event) => addPartner(event?.target)}
            />
            <AddOutlinedIcon sx={{ width: 60, height: 60, opacity: 0.5 }} />
          </label>
        </AddPartnerBox>
      </ProjectPartnersContainer>
    </>
  );
}
export default ProjectCreatePartners;
