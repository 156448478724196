import CloseIcon from '@mui/icons-material/Close';
import { SvgIconProps } from '@mui/material';

function CloseIconCom(props: SvgIconProps) {
  return (
    <CloseIcon
      sx={{
        backgroundColor: 'secondary.light',
        padding: '2px',
        borderRadius: '4px',
        opacity: 0.8,
        cursor: 'pointer',
        ':hover': {
          opacity: 1,
        },
      }}
      {...props}
    />
  );
}

export default CloseIconCom;
