import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import uuid from 'react-uuid';
import useProjectDetails from '@hooks/useProjectDetails';
import { useFormik } from 'formik';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { TProjectDetails, TTeamCardObj } from '@typescript/TProjectDetails';
import { EditBtnStyle } from '@styles/project/SProjectEdit';
import {
  OverviewSubTitle,
  OverviewTitle,
  ProjectTeamCard,
  ProjectTeamCardContainer,
  ProjectTeamCardText,
} from '@styles/project/SProjectSinglePage';
import { CloseBtn, FieldStyled } from '@styles/modal/SModal';
import { AddTeamCardStyled, TextArea } from '@styles/project/SProjectCreate';
import { projectEditTeamInitialValues } from '@formik/project/ProjectInitialData';
import { ProjectTeamValidationSchema } from '@formik/project/ProjectValidation';
import { useMoralisFile } from 'react-moralis';
import { toRem } from '@utils/functions';
import useNotification from '@hooks/useNotification';

interface Props {
  onClose: () => void;
  projectDetails: TProjectDetails;
}

function ProjectTeamEdit({ onClose, projectDetails }: Props) {
  const { notifyError, notifySuccess } = useNotification();

  const { updateProjectDetails } = useProjectDetails();

  const { saveFile } = useMoralisFile();

  const [addItemPressed, setAddItemPressed] = useState<boolean>(false);

  const [allTeamCards, setAllTeamCards] = useState<TTeamCardObj[]>(
    projectDetails.teamCards || [],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addTeamCard = (newTeamCard: TTeamCardObj) => {
    setAllTeamCards((prev) => [...prev, newTeamCard]);
  };

  const removeTeamCard = (id: string) => {
    setAllTeamCards((prev) => prev.filter((teamCard) => teamCard.id !== id));
  };

  const formik = useFormik({
    initialValues: projectEditTeamInitialValues,
    validationSchema: ProjectTeamValidationSchema,
    onSubmit: (values) => {
      addTeamCard(values);
      formik.resetForm();
      setAddItemPressed(false);
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setValues,
    setTouched,
  } = formik;

  const handleAddImage = async (target: HTMLInputElement) => {
    const file = target?.files?.[0];
    if (file) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });

      const url = fileIpfs?._url;

      if (!url) {
        throw Error(`No url found for ${target?.files?.[0]}`);
      }

      setValues((prev) => ({ ...prev, imageUrl: url, id: uuid() }));
      notifySuccess('Image added successfully!');
    }
  };

  const removeTeamCardImage = () => {
    setValues((prev) => ({ ...prev, imageUrl: '' }));
  };

  const handleCloseAddInterface = () => {
    setTouched({ id: true });
    setAddItemPressed(false);
  };

  const handleUpdateTeamCards = async () => {
    if (allTeamCards.length <= 0) return;

    try {
      setIsLoading(true);
      await updateProjectDetails({
        teamCards: allTeamCards,
        id: projectDetails.id,
      });
      notifySuccess('Project Team updated!');
    } catch (error) {
      notifyError('Project Team update failed!');
      throw error;
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  if (isLoading) return <IsLoadingCom dimension="medium" />;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          mb: 2,
          gap: 1,
          marginTop: toRem(16),
        }}
      >
        <Button onClick={handleUpdateTeamCards} variant="outlined">
          Save
        </Button>
        <Button onClick={onClose} sx={EditBtnStyle}>
          <CloseIcon sx={{ width: 20, height: 20 }} />
        </Button>
      </Box>
      {allTeamCards.length === 0 && (
        <Typography variant="h6" color="error" mb={1}>
          Required at least one team card!
        </Typography>
      )}
      <ProjectTeamCardContainer>
        <>
          {allTeamCards.map((card) => (
            <ProjectTeamCard
              key={card.id}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                onClick={() => removeTeamCard(card.id)}
                sx={{
                  ...EditBtnStyle,
                  alignSelf: 'end',
                }}
              >
                <CloseIcon sx={{ width: 20, height: 20 }} />
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  gap: '0.7rem',
                  alignItems: 'center',
                  marginBottom: '1rem',
                  paddingRight: '1rem',
                }}
              >
                <img
                  src={card.imageUrl}
                  alt=""
                  width={56}
                  height={56}
                  style={{ borderRadius: '4px' }}
                />
                <Box>
                  <OverviewTitle>{card.name}</OverviewTitle>
                  <OverviewSubTitle>{card.occupation}</OverviewSubTitle>
                </Box>
              </Box>
              <ProjectTeamCardText>{card.description}</ProjectTeamCardText>
            </ProjectTeamCard>
          ))}
          {addItemPressed ? (
            <form onSubmit={formik.handleSubmit}>
              <AddTeamCardStyled
                sx={{
                  position: 'relative',
                  paddingTop: '0',
                  paddingRight: '0',
                }}
              >
                <CloseBtn
                  sx={{ position: 'unset', m: 1 }}
                  onClick={handleCloseAddInterface}
                >
                  <CloseIcon sx={{ width: 20, height: 20, opacity: 0.5 }} />
                </CloseBtn>
                <Box sx={{ paddingRight: '1rem' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '0.5rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <Box>
                      {values.imageUrl ? (
                        <Box sx={{ position: 'relative' }}>
                          <img
                            src={values.imageUrl}
                            width={36}
                            height={36}
                            style={{
                              borderRadius: '6px',
                              marginInline: '0.5rem',
                            }}
                            alt=""
                          />
                          <CloseBtn
                            onClick={removeTeamCardImage}
                            sx={{ padding: '0.1rem' }}
                          >
                            <CloseIcon
                              sx={{ width: 15, height: 15, opacity: 0.5 }}
                            />
                          </CloseBtn>
                        </Box>
                      ) : (
                        <Button
                          component="label"
                          htmlFor="teamImage"
                          sx={{
                            padding: '0',
                            marginInline: '0.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <AddPhotoAlternateIcon
                              sx={{ width: 36, height: 36 }}
                            />
                            {touched.imageUrl && errors.imageUrl && (
                              <Typography
                                variant="h6"
                                color="error.main"
                                sx={{
                                  fontSize: '12px !important',
                                  fontWeight: '400',
                                }}
                              >
                                Required!
                              </Typography>
                            )}
                          </Box>
                          <input
                            accept="image/*"
                            hidden
                            id="teamImage"
                            multiple
                            type="file"
                            onChange={(event) => handleAddImage(event.target)}
                          />
                        </Button>
                      )}
                    </Box>
                    <Box display="grid" width="100%">
                      <FieldStyled
                        sx={{ marginBottom: '1.3rem' }}
                        id="name"
                        name="name"
                        label="Name.."
                        onBlur={handleBlur}
                        onInput={handleChange}
                        value={values.name}
                        helperText={touched.name ? errors.name : ''}
                        error={Boolean(errors.name) && touched.name}
                      />
                      <FieldStyled
                        id="occupation"
                        name="occupation"
                        label="Occupation.."
                        onBlur={handleBlur}
                        onInput={handleChange}
                        value={values.occupation}
                        helperText={touched.occupation ? errors.occupation : ''}
                        error={Boolean(errors.occupation) && touched.occupation}
                      />
                    </Box>
                  </Box>
                  {touched.description && errors.description && (
                    <Typography
                      variant="h6"
                      color="error.main"
                      sx={{ fontSize: '12px !important', fontWeight: '400' }}
                      mb={1}
                      ml={1}
                    >
                      Required!
                    </Typography>
                  )}
                  <TextArea
                    id="description"
                    sx={{
                      alignSelf: 'center',
                      marginBottom: '0.5rem',
                    }}
                    placeholder="Description.."
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{ borderRadius: '6px' }}
                  >
                    Create
                  </Button>
                </Box>
              </AddTeamCardStyled>
            </form>
          ) : (
            <Button
              onClick={() => setAddItemPressed(true)}
              sx={{
                padding: '2rem',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'secondary.light',
                borderRadius: '6px',
                ':hover': {
                  backgroundColor: 'secondary.light',
                },
              }}
            >
              <AddOutlinedIcon sx={{ width: 60, height: 60, opacity: 0.5 }} />
            </Button>
          )}
        </>
      </ProjectTeamCardContainer>
    </>
  );
}
export default ProjectTeamEdit;
