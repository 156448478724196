import { Box, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { ProjectTable, ProjectTitle } from '@styles/project/SProjectSinglePage';
import { NO_TRANSACTIONS_MS } from '@constants/CDefaultMessages';
import { useGetMyTradeTxByProjectQuery } from '@store/api/transactionApi';
import { getTradeTxByProjectZod } from '@typescript/dtos/transaction/getTransaction.dto';
import { baseTradeTxColumns } from '@components/tables-columns/TradeTxColumns';
import { useState } from 'react';
import DataWrapper from '@components/shared/DataWrapper';
import ProjectTransactionsResponsive from './ProjectTransactionsResponsive';

interface Props {
  projectId: string;
}

function ProjectTransactions({ projectId }: Props) {
  const { breakpoints } = useTheme();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const down2Lg = useMediaQuery(breakpoints.down('2lg'));

  const {
    data: { resource: myTradeTx, count } = { resource: [], count: 0 },
    isLoading,
  } = useGetMyTradeTxByProjectQuery(
    getTradeTxByProjectZod.parse({
      id: projectId,
      page: page + 1,
      pageSize: down2Lg ? pageSize : 5,
    }),
  );

  const displayContent = () => {
    if (down2Lg) {
      return (
        <ProjectTransactionsResponsive
          transactions={myTradeTx}
          count={count}
          isLoading={isLoading}
          actionSeeMore={() => {
            setPage(0);
            setPageSize(pageSize + 5);
          }}
        />
      );
    }
    return (
      <ProjectTable>
        <DataGrid
          sx={{ ...DataGridCustomStyles, mt: 0, border: 'none' }}
          rows={myTradeTx}
          columns={baseTradeTxColumns}
          autoHeight
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          initialState={{
            pagination: { pageSize: 5, page },
          }}
          pagination
          rowsPerPageOptions={[5]}
          paginationMode="server"
          rowCount={count}
          onPageChange={(newPage) => setPage(newPage)}
        />
      </ProjectTable>
    );
  };

  return (
    <Box sx={{ mb: '1rem' }}>
      <DataWrapper
        data={myTradeTx}
        isLoading={isLoading}
        defaultOptions={{ text: NO_TRANSACTIONS_MS }}
      >
        <Box
          sx={{
            display: down2Lg ? 'flex' : 'block',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ProjectTitle sx={{ mb: '1rem' }}>Project Transactions</ProjectTitle>
        </Box>
        {displayContent()}
      </DataWrapper>
    </Box>
  );
}

export default ProjectTransactions;
