import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TAllocation } from '@typescript/models/Allocation.model';
import colors from '@theme/customColors';
import { BoxContainer, ProjectName } from '@styles/project/SProjectCardElem';
import {
  borderRadius6,
  greenTextShadow,
  redTextShadow,
} from '@styles/shared/SCss';
import { cryptoFormat, formatNr } from '@utils/functions';
import { CardHeader } from '@styles/portfolio/SPortfolio';
import { ProjectStatus } from '@constants/CProject';
import useStyles from '@hooks/useStyles';
import { SellButton } from '@styles/shared/SButtons';
import useActionOrConnect from '@hooks/useActionOrConnect';

interface Props {
  allocation: TAllocation;
  onOpenModal: (allocation: TAllocation) => void;
}

function PortfolioCard({ allocation, onOpenModal }: Props) {
  const { border1pxSecondaryLight } = useStyles();
  const handleActionOrConnect = useActionOrConnect();

  const navigate = useNavigate();

  let projectPercentage =
    (100 * allocation.unlockedTokens) / allocation.totalTokens;
  projectPercentage = +formatNr(projectPercentage, undefined, 2);

  const handleCardHeaderLink = () => {
    navigate(`/projects/${allocation.project.slug}`);
  };

  const getProgressColors = () => {
    let backgroundColor = colors.darkBlueText;
    let hoverColor = colors.darkBlueOpacity;

    if (allocation.project.status === ProjectStatus.DISTRIBUTING) {
      backgroundColor = colors.darkOrangeText;
      hoverColor = colors.orange;
    }
    if (allocation.project.status === ProjectStatus.COMPLETED) {
      backgroundColor = colors.darkGreenText;
      hoverColor = colors.successMain;
    }
    return {
      height: '10px',
      borderRadius: '20px',
      backgroundColor,
      '& .MuiLinearProgress-bar': {
        borderRadius: '6px',
        backgroundColor: hoverColor,
      },
    };
  };

  const handleOpen = () => {
    onOpenModal(allocation);
  };

  const actionButton = () => {
    switch (allocation.project.status) {
      case ProjectStatus.LIVE:
        return null;
      case ProjectStatus.LIVE_PRIVATE:
        return null;
      case ProjectStatus.REFUNDED:
        return null;
      default:
        return (
          <SellButton
            fullWidth
            disableRipple
            disabled={allocation.lockedTokens <= 0 || allocation.project.hidden}
            onClick={() => handleActionOrConnect(handleOpen)}
          >
            Sell
          </SellButton>
        );
    }
  };

  return (
    <BoxContainer key={allocation.id}>
      <CardHeader onClick={handleCardHeaderLink}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={allocation.project.image}
            width={40}
            height={40}
            alt=""
            style={borderRadius6}
          />
          <ProjectName>{allocation.project.name}</ProjectName>
          <Typography variant="h3" sx={{ opacity: 0.5 }}>
            {allocation.project.symbol}
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={{
            color:
              allocation.project.performance >= 0
                ? 'success.main'
                : 'error.main',
            fontWeight: '400',
            textShadow:
              allocation.project.performance >= 0
                ? greenTextShadow
                : redTextShadow,
            ml: '0.5rem',
          }}
        >
          {allocation.project.performance >= 0 && '+'}
          {formatNr(allocation.project.performance, undefined, 2)}%
        </Typography>
      </CardHeader>
      <Box
        sx={{
          display: {
            xs: 'grid',
            xl: 'flex',
          },
          gridTemplateColumns: 'repeat(2, 1fr)',
          borderBottom: border1pxSecondaryLight,
        }}
      >
        <Box
          sx={{
            borderRight: border1pxSecondaryLight,
            borderBottom: { xs: border1pxSecondaryLight, xl: 'none' },
            padding: '1rem',
            flex: 1,
          }}
        >
          <Typography variant="h6" mb={2} sx={{ opacity: 0.5 }}>
            Balance
          </Typography>
          <Tooltip title={allocation.lockedTokens} placement="top-start">
            <Typography variant="h4" sx={{ fontWeight: '400' }}>
              {formatNr(allocation.lockedTokens, undefined, 0)}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            borderRight: border1pxSecondaryLight,
            borderBottom: { xs: border1pxSecondaryLight, xl: 'none' },
            padding: '1rem',
            flex: 1,
          }}
        >
          <Typography variant="h6" mb={2} sx={{ opacity: 0.5 }}>
            Value
          </Typography>
          <Tooltip title={allocation.lockedValue} placement="top-start">
            <Typography variant="h4" sx={{ fontWeight: '400' }}>
              {formatNr(allocation.lockedValue, true, 0)}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            borderRight: border1pxSecondaryLight,
            padding: '1rem',
            flex: 1.5,
          }}
        >
          <Typography variant="h6" mb={2} sx={{ opacity: 0.5 }}>
            Current Token Price
          </Typography>
          <Tooltip
            title={allocation.project.currentPrice}
            placement="top-start"
          >
            <Typography variant="h4" sx={{ fontWeight: '400' }}>
              {cryptoFormat(allocation.project.currentPrice, {
                fontSize: '16px !important',
              })}
            </Typography>
          </Tooltip>
        </Box>
        <Box sx={{ padding: '1rem', flex: 1 }}>
          <Typography variant="h6" mb={2} sx={{ opacity: 0.5 }}>
            Token Price
          </Typography>
          <Tooltip title={allocation.project.tokenPrice} placement="top-start">
            <Typography variant="h4" sx={{ fontWeight: '400' }}>
              {cryptoFormat(allocation.project.tokenPrice, {
                fontSize: '16px !important',
              })}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: '1rem 1rem 0.6rem 1rem',
          borderBottom: border1pxSecondaryLight,
        }}
      >
        <LinearProgress
          variant="determinate"
          value={projectPercentage}
          sx={getProgressColors()}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '0.7rem',
          }}
        >
          <Typography sx={{ opacity: 0.5 }} variant="h6">
            {projectPercentage}%
          </Typography>
          <Tooltip
            title={`${allocation.unlockedTokens} / ${allocation.totalTokens}`}
            placement="top-start"
          >
            <Typography sx={{ opacity: 0.5 }} variant="h6">
              {formatNr(allocation.unlockedTokens, undefined, 0)} /
              {formatNr(allocation.totalTokens, undefined, 0)}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      {actionButton()}
    </BoxContainer>
  );
}

export default PortfolioCard;
