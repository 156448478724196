import { Button, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CloseBtn } from '@styles/project/SProjectCreate';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import useProject from '@hooks/useProject';
import { useFormik } from 'formik';
import { flexCentered } from '@styles/shared/SCss';
import { Svgs } from '@utils/svgs';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { useMoralisFile } from 'react-moralis';
import { FieldStyled } from '@styles/modal/SModal';
import { HeaderProject } from '@styles/project/SProjectSinglePage';
import { EditBtnStyle } from '@styles/project/SProjectEdit';
import { ProjectEditHeaderValidationSchema } from '@formik/project/ProjectValidation';
import { projectEditHeaderInitialValues } from '@formik/project/ProjectInitialData';
import { TProjectHeaderInterface } from '@typescript/TProjectDetails';
import { TProject } from '@typescript/models/Project.model';
import useNotification from '@hooks/useNotification';

interface Props {
  project: TProject;
  onClose: () => void;
}

function ProjectEditHeader({ project, onClose }: Props) {
  const { notifyError, notifySuccess } = useNotification();

  const { saveFile } = useMoralisFile();

  const navigate = useNavigate();

  const { updateProject } = useProject();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialValues = projectEditHeaderInitialValues(project);

  const formik = useFormik({
    initialValues,
    validationSchema: ProjectEditHeaderValidationSchema,
    onSubmit: async (values: TProjectHeaderInterface) => {
      try {
        setIsLoading(true);
        await updateProject({ ...values, id: project.id });
        // if (values. !== initialValues.name) {
        //   navigate(`/projects/${values.slug}`);
        // }
        notifySuccess('Project Header updated!');
      } catch (error) {
        notifyError('Project Header update failed!');
        throw error;
      } finally {
        setIsLoading(false);
        onClose();
      }
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setValues,
    setTouched,
  } = formik;

  const handleAddImage = async (event: HTMLInputElement) => {
    const file = event.files?.[0];

    if (file) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });
      if (fileIpfs) {
        setValues((prev) => ({ ...prev, image: fileIpfs._url }));
      }
    }
  };

  const removeImage = () => {
    setValues((prev) => ({ ...prev, image: '' }));
    setTouched({ image: true });
  };

  const handleSubmitButton = () => {
    formik.submitForm();
  };

  if (isLoading) return <IsLoadingCom dimension="medium" />;

  return (
    <HeaderProject sx={{ position: 'relative', pt: '2.2rem ' }}>
      <IconButton onClick={() => navigate(-1)} sx={{ mt: 2 }}>
        <Svgs.BigLeftArrow style={{ padding: '0.2rem' }} />
      </IconButton>
      {values.image ? (
        <Box sx={{ position: 'relative' }}>
          <img
            src={values.image}
            style={{ borderRadius: '6px', marginInline: '0.5rem' }}
            width={58}
            height={58}
            alt=""
          />
          <CloseBtn onClick={removeImage} sx={{ padding: '0.1rem' }}>
            <CloseIcon sx={{ width: 15, height: 15 }} />
          </CloseBtn>
        </Box>
      ) : (
        <Button
          component="label"
          htmlFor="projectImage"
          sx={{
            padding: '0',
            marginInline: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Box sx={{ ...flexCentered, flexDirection: 'column' }}>
            <AddPhotoAlternateIcon sx={{ width: 58, height: 58 }} />
            {touched.image && errors.image && (
              <Typography
                variant="h6"
                color="error.main"
                sx={{ fontSize: '12px !important', fontWeight: '400' }}
              >
                Required!
              </Typography>
            )}
          </Box>
          <input
            accept="image/*"
            hidden
            id="projectImage"
            multiple
            type="file"
            onChange={(event) => handleAddImage(event.target)}
            onBlur={handleBlur}
          />
        </Button>
      )}
      <FieldStyled
        id="name"
        name="name"
        label="Name"
        sx={{ minHeight: '90px' }}
        value={values.name}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        helperText={errors.name}
        error={Boolean(errors.name)}
      />
      <FieldStyled
        id="symbol"
        name="symbol"
        label="Symbol"
        sx={{ minHeight: '90px', ml: 2 }}
        value={values.symbol}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        helperText={errors.symbol}
        error={Boolean(errors.symbol)}
      />
      <Button
        disabled={Object.keys(errors).length !== 0}
        variant="outlined"
        sx={{
          borderRadius: '4px',
          width: { xs: '125px', xsm: '175px' },
          px: { xs: 0.5, xsm: 2 },
          py: '19px',
          marginLeft: 'auto',
          marginRight: '1rem',
        }}
        onClick={handleSubmitButton}
      >
        Save
      </Button>
      <Button onClick={onClose} sx={{ ...EditBtnStyle }}>
        <CloseIcon sx={{ width: 20, height: 20 }} />
      </Button>
    </HeaderProject>
  );
}
export default ProjectEditHeader;
