import { Box } from '@mui/material';
import { TProjectDetails } from '@typescript/TProjectDetails';
import {
  OverviewTitle,
  ProjectSocialCard,
  ProjectSocialCardContainer,
} from '@styles/project/SProjectSinglePage';
import EditIconCom from '@components/shared/EditIconCom';
import useUser from '@hooks/useUser';

interface Props {
  projectDetails: TProjectDetails;
  onOpen: () => void;
}

function ProjectSocial({ projectDetails, onOpen }: Props) {
  const { isAdmin } = useUser();

  if (projectDetails.socialCards) {
    return (
      <Box id="socials">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <OverviewTitle mb={2} mt={4}>
            Social
          </OverviewTitle>

          {isAdmin && (
            <Box>
              <EditIconCom openEditSection={onOpen} />
            </Box>
          )}
        </Box>
        <ProjectSocialCardContainer>
          {projectDetails.socialCards.map((card) => (
            <ProjectSocialCard key={card.id}>
              <a
                aria-label="project url"
                href={card.url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={card.imageUrl}
                  width={45}
                  height={45}
                  style={{ borderRadius: '6px' }}
                  alt=""
                />
              </a>
            </ProjectSocialCard>
          ))}
        </ProjectSocialCardContainer>
      </Box>
    );
  }

  if (isAdmin && !projectDetails.socialCards) {
    return (
      <Box id="socials">
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
          <EditIconCom openEditSection={onOpen} />
        </Box>
        <OverviewTitle mb={2} mt={4}>
          Social
        </OverviewTitle>
      </Box>
    );
  }

  return null;
}

export default ProjectSocial;
