import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { OverviewTitle } from '@styles/project/SProjectSinglePage';
import gfm from 'remark-gfm';
import { Box } from '@mui/material';
import { TProjectDetails } from '@typescript/TProjectDetails';
import EditIconCom from '@components/shared/EditIconCom';
import useUser from '@hooks/useUser';

interface Props {
  projectDetails: TProjectDetails;
  onClose: () => void;
}

function ProjectOverview({ projectDetails, onClose }: Props) {
  const { isAdmin } = useUser();

  if (projectDetails.overviewText) {
    return (
      <Box sx={{ pre: { whiteSpace: 'break-spaces' } }} id="overview">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <OverviewTitle mb={2}>Overview</OverviewTitle>
          {isAdmin && (
            <Box>
              <EditIconCom openEditSection={onClose} />
            </Box>
          )}
        </Box>
        <ReactMarkdown remarkPlugins={[gfm]}>
          {projectDetails.overviewText}
        </ReactMarkdown>
      </Box>
    );
  }

  if (isAdmin && !projectDetails.overviewText) {
    return (
      <Box id="overview">
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <EditIconCom openEditSection={onClose} />
        </Box>
      </Box>
    );
  }

  return null;
}

export default ProjectOverview;
