import { Box, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { TListing } from '@typescript/models/Listing.model';
import { SeeMoreButton } from '@styles/portfolio/SPortfolio';
import useStyles from '@hooks/useStyles';
import {
  ListingStatusCell,
  askingPriceCell,
  currentTokenPriceCell,
  expireInCell,
  negotiableCell,
  offeredPriceCell,
  tokenAmountCell,
  totalCostCell,
} from '@components/tables-columns/TableCell';

interface Props {
  listings: TListing[];
}

function ProjectListingsTableResponsive({ listings }: Props) {
  const navigate = useNavigate();

  const { border1pxSecondaryLight } = useStyles();

  return (
    <Box mt={2}>
      <Box
        sx={{
          display: 'grid',
          gap: '0.7rem',
          maxWidth: { xs: '450px', md: 'unset' },
          marginInline: 'auto',
        }}
      >
        {listings.map((listing) => (
          <Box
            key={listing.id}
            sx={{
              borderRadius: '6px',
              border: border1pxSecondaryLight,
              backgroundColor: 'secondary.main',
            }}
          >
            <Box
              sx={{
                borderBottom: border1pxSecondaryLight,
                borderRadius: '4px 4px 0 0',
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <img
                  src={listing.project.image}
                  style={{ borderRadius: '6px', width: 48, height: 48 }}
                  alt=""
                />
                <Typography
                  sx={{
                    pl: 1,
                    fontWeight: 400,
                    fontSize: '17px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'none',
                    },
                  }}
                  onClick={() => navigate(`/projects/${listing.project.slug}`)}
                >
                  {listing.project.name}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'grid', md: 'flex' },
                gridTemplateColumns: { xs: '1fr 1fr' },
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: { md: border1pxSecondaryLight },
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Current Token Price
                </Typography>
                {currentTokenPriceCell(listing.project.currentPrice).renderCell}
              </Box>
              <Box
                sx={{
                  p: 2,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: border1pxSecondaryLight,
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Asking Price
                </Typography>
                {askingPriceCell(listing).renderCell}
              </Box>
              <Box
                sx={{
                  p: 2,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: { md: border1pxSecondaryLight },
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Token Amount
                </Typography>
                {tokenAmountCell(listing.amount).renderCell}
              </Box>
              <Box
                sx={{
                  p: 2,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: border1pxSecondaryLight,
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Total Cost
                </Typography>
                {totalCostCell(listing.price).renderCell}
              </Box>
              <Box
                sx={{
                  p: 2,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: { xs: 'none', md: border1pxSecondaryLight },
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Offered Price
                </Typography>
                {offeredPriceCell(listing).renderCell}
              </Box>
              <Box
                sx={{
                  p: 2,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: border1pxSecondaryLight,
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Negotiable
                </Typography>
                {negotiableCell(listing).renderCell}
              </Box>
              <Box
                sx={{
                  p: 2,
                  borderRight: { xs: 'none', md: border1pxSecondaryLight },
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Expire In
                </Typography>
                {expireInCell(listing).renderCell}
              </Box>
              <Box
                sx={{
                  p: 2,
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Status
                </Typography>
                <ListingStatusCell listing={listing} />
              </Box>
            </Box>
          </Box>
        ))}
        <SeeMoreButton
          sx={{ width: '100% !important', borderTop: border1pxSecondaryLight }}
          onClick={() => navigate('/explore')}
        >
          Explore more
        </SeeMoreButton>
      </Box>
    </Box>
  );
}
export default ProjectListingsTableResponsive;
