import {
  Box,
  CircularProgress,
  colors,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { LinkStyled } from '@styles/shared/SLinks';
import useUtils from '@hooks/useUtils';
import { TTransactionInvestment } from '@typescript/models/InvestmentTransaction.model';
import useStyles from '@hooks/useStyles';
import useUser from '@hooks/useUser';
import { BuyGradientDark } from '@styles/shared/SButtons';

interface Props {
  transactionInvestment: TTransactionInvestment[];
  count: number;
  isLoading: boolean;
  actionSeeMore: () => void;
}
function TransactionsInvestmentResponsive({
  transactionInvestment,
  count,
  isLoading,
  actionSeeMore,
}: Props) {
  const { breakpoints } = useTheme();

  const { currentUser: user } = useUser();

  const { serverNetwork } = useUtils();

  const navigate = useNavigate();

  const { border1pxSecondaryLight } = useStyles();

  const downMd = useMediaQuery(breakpoints.down('md'));

  const getFromValue = (row: TTransactionInvestment) => {
    const slice = downMd ? 4 : 10;

    if (row && row.from) {
      const userAddress = user?.accounts[0];
      const from = row.from.ethAddress;
      const fromEdited = `${from.slice(0, slice)}...${from.slice(slice * -1)}`;
      const networkLink =
        serverNetwork === 'bsc'
          ? 'https://bscscan.com/address/'
          : 'https://testnet.bscscan.com/address/';

      const link = `${networkLink}${from}`;
      return (
        <LinkStyled href={link} target="_blank" aria-label="wallet address">
          {userAddress === from ? 'You' : fromEdited}
        </LinkStyled>
      );
    }
    return null;
  };

  const getToValue = (row: TTransactionInvestment) => {
    if (row && row.to) {
      const userAddress = user?.accounts[0];
      const { to } = row;
      const fromEdited = `${to.slice(0, 4)}...${to.slice(-4)}`;
      const networkLink =
        serverNetwork === 'bsc'
          ? 'https://bscscan.com/address/'
          : 'https://testnet.bscscan.com/address/';

      const link = `${networkLink}${to}`;
      return (
        <LinkStyled
          underline="always"
          sx={{ color: colors.blue }}
          href={link}
          target="_blank"
          aria-label="wallet address"
        >
          {userAddress === to ? 'You' : fromEdited}
        </LinkStyled>
      );
    }

    return null;
  };

  const getTimeValue = (row: TTransactionInvestment) => {
    if (row.id) {
      const { createdAt } = row;
      const networkLink =
        serverNetwork === 'bsc'
          ? 'https://bscscan.com/tx/'
          : 'https://testnet.bscscan.com/tx/';

      const link = `${networkLink}${row.transactionHash}`;
      return (
        <LinkStyled
          target="_blank"
          href={link}
          aria-label="transaction time"
          sx={{ marginRight: '0.3rem' }}
        >
          {moment(createdAt).fromNow()}
        </LinkStyled>
      );
    }
    return null;
  };

  return (
    <Box sx={{ pb: '5rem' }}>
      <Box sx={{ display: 'grid', gap: '0.7rem', px: '16px', mb: '1rem' }}>
        {transactionInvestment.map((transaction) => (
          <Box
            key={transaction.id}
            sx={{
              borderRadius: '6px',
              border: border1pxSecondaryLight,
              backgroundColor: 'secondary.main',
            }}
          >
            <Box
              sx={{
                borderBottom: border1pxSecondaryLight,
                borderRadius: '4px 4px 0 0',
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={transaction.project.image}
                  style={{ borderRadius: '6px', width: 48, height: 48 }}
                  alt=""
                />
                <Typography
                  sx={{
                    pl: 1,
                    fontWeight: 400,
                    fontSize: '17px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'none',
                    },
                  }}
                  onClick={() =>
                    navigate(`/projects/${transaction.project.slug}`)
                  }
                >
                  {transaction.project.name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Time
                </Typography>
                <Typography mt={1}>{getTimeValue(transaction)}</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr 1fr', xsm: '1fr 1fr 1fr 1fr ' },
              }}
            >
              <Box
                sx={{
                  p: 3,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Investment Amount
                </Typography>
                <Typography mt={1}>${transaction.investmentAmount}</Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Total Tokens
                </Typography>
                <Typography mt={1}>{transaction.totalTokens}</Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  borderRight: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    opacity: 0.5,
                  }}
                >
                  From
                </Typography>
                <Typography mt={1}>{getFromValue(transaction)}</Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderRight: border1pxSecondaryLight,
                  borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  To
                </Typography>
                <Typography mt={1}>{getToValue(transaction)}</Typography>
              </Box>
            </Box>
          </Box>
        ))}
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: count === transactionInvestment.length ? 'none' : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ maxWidth: '200px' }}>
              <BuyGradientDark onClick={actionSeeMore}>
                See More
              </BuyGradientDark>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default TransactionsInvestmentResponsive;
