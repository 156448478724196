import { Box, Typography, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { setShowLoginPopup } from '@store/slices/connectSlice';
import {
  BoxContainer,
  CardHeader,
  CardH6Text,
  ProgressDetailsBox,
  ProgressDetailsDivider,
  CurrentPriceBox,
} from '@styles/project/SProjectCardElem';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ServerNetwork,
  calculateProfitLoss,
  cryptoFormat,
} from '@utils/functions';
import { useGetServerNetworkQuery } from '@store/api/utilApi';
import { borderRadius6, flexColumn } from '@styles/shared/SCss';
import { BuyButton } from '@styles/shared/SButtons';
import useUser from '@hooks/useUser';
import { TProject } from '@typescript/models/Project.model';
import { EthInvestmentModalStep } from '@typescript/TModalFormik';
import InvestmentEthModals from '@components/modals/eth-investment/InvestmentEthModals';
import { ChainId } from '@constants/CChains';
import { useChain } from 'react-moralis';
import { useAppDispatch } from '@store/store';
import { ProjectStatus } from '@constants/CProject';
import { decryptWithSecret } from '@utils/encryptSlug';
import ProjectStatusCom from '../helpers/ProjectStatusCom';

interface Props {
  project: TProject;
}

function ProjectDealsEthCard({ project }: Props) {
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const { data: serverNetwork } = useGetServerNetworkQuery({});

  const { chainId } = useChain();

  const navigate = useNavigate();

  const { currentUser,isLoading } = useUser();

  const { label } = ProjectStatusCom({ project });

  const [modalEthInvestment, setModalEthInvestment] =
    useState<null | EthInvestmentModalStep>(null);

  const handleLiveInvest = () => {
    if (!currentUser) {
      dispatch(setShowLoginPopup(true));
      return;
    }

    if (serverNetwork === ServerNetwork.BSC && chainId !== ChainId.ETH) {
      setModalEthInvestment(EthInvestmentModalStep.SWITCH_CHAIN);
      return;
    }

    if (
      serverNetwork === ServerNetwork.BSC_TESTNET &&
      chainId !== ChainId.SEPOLIA
    ) {
      setModalEthInvestment(EthInvestmentModalStep.SWITCH_CHAIN);
    } else {
      setModalEthInvestment(EthInvestmentModalStep.STEP_1);
    }
  };

  const displayButton = () => {
    if (project.status === ProjectStatus.LIVE) {
      return (
        <Box
          sx={{
            display: 'flex',
            borderTop: `1px solid ${theme.palette.secondary.light}`,
          }}
        >
          <BuyButton
            fullWidth
            variant="text"
            onClick={handleLiveInvest}
            disableRipple
          >
            Invest
          </BuyButton>
        </Box>
      );
    }
    return null;
  };

  useEffect(() => {
    const idProject = searchParams.get('id');
    if (idProject) {
      const decryptId = decryptWithSecret(idProject);
      if (project.id === decryptId && !isLoading) {
        handleLiveInvest();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, project, isLoading]);

  return (
    <>
      <BoxContainer>
        <CardHeader onClick={() => navigate(project.slug)}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            mr={1}
          >
            <img
              src={project.image}
              width={40}
              height={40}
              alt=""
              style={borderRadius6}
            />
            <Box marginLeft="5px">
              <Typography>{project.name}</Typography>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.text.secondary, fontSize: 12 }}
              >
                {project.symbol}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          >
            {label}
          </Box>
        </CardHeader>

        <ProgressDetailsBox>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              component="span"
              fontSize={13}
              fontWeight={700}
              mr="4px"
              lineHeight="initial"
            >
              {project.countWTB}
            </Typography>
            <CardH6Text>Buyers</CardH6Text>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              component="span"
              fontSize={13}
              fontWeight={700}
              mr="4px"
              lineHeight="initial"
            >
              {project.countWTS}
            </Typography>
            <CardH6Text>Sellers</CardH6Text>
          </Box>

          <ProgressDetailsDivider />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              component="span"
              fontSize={13}
              fontWeight={700}
              mr="4px"
              lineHeight="initial"
            >
              0
            </Typography>
            <CardH6Text>to Claim</CardH6Text>
          </Box>
        </ProgressDetailsBox>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            padding: '10px',
          }}
        >
          <Box sx={{ ...flexColumn, marginRight: '15px' }}>
            <Box
              sx={{
                display: 'flex',
                paddingBottom: '8px',
                alignItems: 'center',
              }}
            >
              <CardH6Text>Price</CardH6Text>
              <Tooltip title={+project.tokenPrice} placement="right-end">
                <CurrentPriceBox>
                  {cryptoFormat(project.tokenPrice, {
                    fontSize: '12px !important',
                  })}
                </CurrentPriceBox>
              </Tooltip>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CardH6Text>Type</CardH6Text>

              <Typography variant="h6">{project.round}</Typography>
            </Box>
          </Box>

          <Box sx={{ ...flexColumn }}>
            <Box
              sx={{
                display: 'flex',
                paddingBottom: '8px',
                alignItems: 'center',
              }}
            >
              <CardH6Text>Current Price</CardH6Text>

              <Tooltip title={project.currentPrice} placement="right-end">
                <CurrentPriceBox>
                  {cryptoFormat(project.currentPrice, {
                    fontSize: '12px !important',
                  })}
                </CurrentPriceBox>
              </Tooltip>

              <Box
                sx={{
                  marginLeft: '4px',
                  '& p': {
                    fontSize: '12px',
                  },
                }}
              >
                {calculateProfitLoss(+project.tokenPrice, project.currentPrice)}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CardH6Text>Network</CardH6Text>

              <img
                src={project.networkImage}
                style={{
                  marginRight: '0.3rem',
                  borderRadius: '2px',
                }}
                width={16}
                height={16}
                alt=""
              />

              <Typography variant="h6">{project.chain}</Typography>
            </Box>
          </Box>
        </Box>
        {displayButton()}
      </BoxContainer>
      {modalEthInvestment !== null && (
        <InvestmentEthModals
          step={modalEthInvestment}
          setStep={setModalEthInvestment}
          project={project}
        />
      )}
    </>
  );
}
export default ProjectDealsEthCard;
