import { Box } from '@mui/material';
import { TProjectDetails } from '@typescript/TProjectDetails';
import {
  OverviewSubTitle,
  OverviewTitle,
  ProjectTeamCard,
  ProjectTeamCardContainer,
  ProjectTeamCardText,
} from '@styles/project/SProjectSinglePage';
import EditIconCom from '@components/shared/EditIconCom';
import useUser from '@hooks/useUser';

interface Props {
  projectDetails: TProjectDetails;
  onOpen: () => void;
}

function ProjectTeam({ projectDetails, onOpen }: Props) {
  const { isAdmin } = useUser();

  if (projectDetails.teamCards) {
    return (
      <Box id="team">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <OverviewTitle mb={2} mt={4}>
            Team & Advisors
          </OverviewTitle>
          {isAdmin && (
            <Box>
              <EditIconCom openEditSection={onOpen} />
            </Box>
          )}
        </Box>
        <ProjectTeamCardContainer>
          {projectDetails.teamCards.map((card) => (
            <ProjectTeamCard key={card.id}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={card.imageUrl}
                  alt=""
                  width={56}
                  height={56}
                  style={{ borderRadius: '4px' }}
                />
                <Box ml={2}>
                  <OverviewTitle>{card.name}</OverviewTitle>
                  <OverviewSubTitle>{card.occupation}</OverviewSubTitle>
                </Box>
              </Box>
              <ProjectTeamCardText mt={2}>
                {card.description}
              </ProjectTeamCardText>
            </ProjectTeamCard>
          ))}
        </ProjectTeamCardContainer>
      </Box>
    );
  }

  if (isAdmin && !projectDetails.teamCards) {
    return (
      <Box id="team">
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <EditIconCom openEditSection={onOpen} />
        </Box>
        <OverviewTitle mb={2} mt={4}>
          Team & Advisors
        </OverviewTitle>
      </Box>
    );
  }

  return null;
}

export default ProjectTeam;
