import { TAllocation } from '@typescript/models/Allocation.model';
import { Box, CircularProgress } from '@mui/material';
import { CardsContainer } from '@styles/portfolio/SPortfolio';
import PortfolioCard from '@components/portfolio/PortfolioCard';
import { BuyGradientDark } from '@styles/shared/SButtons';
import { useEffect, useState } from 'react';
import { useGetMyAllocationsQuery } from '@store/api/allocationApi';
import DataWrapper from '@components/shared/DataWrapper';
import { Svgs } from '@utils/svgs';
import { NO_ALLOCATIONS_MS } from '@constants/CDefaultMessages';

interface Props {
  onOpenModal: (allocation: TAllocation) => void;
}

function AllocationsView({ onOpenModal }: Props) {
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(10);

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const {
    data: { resource: myAllocations, count } = { resource: [], count: 0 },
    isLoading,
    isFetching,
  } = useGetMyAllocationsQuery({ page: page + 1, pageSize });

  const loadMore = () => {
    setPage(0);
    setPageSize(pageSize + 5);
    setIsLoadingMore(true);
  };

  useEffect(() => {
    if (isLoadingMore && !isFetching) {
      setIsLoadingMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <DataWrapper
      isLoading={isLoading}
      isLoadingOptions={{ dimension: 'contained' }}
      data={myAllocations}
      defaultOptions={{
        icon: Svgs.EmptyProjects,
        text: NO_ALLOCATIONS_MS,
      }}
    >
      <Box sx={{ mx: 2 }}>
        <CardsContainer>
          {myAllocations.map((allocation) => (
            <PortfolioCard
              key={allocation.id}
              onOpenModal={onOpenModal}
              allocation={allocation}
            />
          ))}
        </CardsContainer>

        {isLoadingMore ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: count === myAllocations.length ? 'none' : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <BuyGradientDark onClick={loadMore}>See More</BuyGradientDark>
            </Box>
          </Box>
        )}
      </Box>
    </DataWrapper>
  );
}

export default AllocationsView;
